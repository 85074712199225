import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as DeclareSearchStore from '../store/DeclareSearchStore';
import { DeclareLabelThumbnail } from '../store/Models';

// ===============================================================================

// At runtime, Redux will merge together...
type DeclareSearchProps =
    DeclareSearchStore.DeclareSearchState & // ... state we've requested from the Redux store
    typeof DeclareSearchStore.actionCreators &
    RouteComponentProps<{ declareLabelNameId: string }>; // ... plus action creators we've requested

// ===============================================================================

class DeclareSearch extends React.PureComponent<DeclareSearchProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.initiateGetSearchResultsForQuery();
    }

    // ===============================================================================

    // This method is called when the route parameters change
    public componentDidUpdate() {
    }

    // ===============================================================================

    public componentWillUnmount() {

    }

    // ===============================================================================

    public render() {
        const noResultsFound = this.props.searchResults.length === 0;

        if (noResultsFound) {
            return this.renderNoResultsFoundForm();
        }

        return (
            <div className="container">
                {this.props.history.length > 1 &&
                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-default" onClick={(evt) => { this.props.history.goBack(); }}>Back</button>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="lf-content lf-content-full lf-content-search">
                            <header className="single-page-header">
                                <div className="post-title">
                                    <h3>Search Results for: <span>{this.props.query}</span></h3>
                                </div>
                            </header>
                            {this.props.isLoading &&
                                <p>Loading...</p>}
                            {!this.props.isLoading &&
                                this.props.searchResults.map(declareLabelThumbnail =>
                                    this.renderSearchResult(declareLabelThumbnail))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // ===============================================================================

    private renderNoResultsFoundForm() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="lf-content lf-content-full lf-content-search">
                            <section className="no-results not-found">
                                <header className="page-header">
                                    <h1 className="page-title">Nothing Found</h1>
                                </header>
                            	<div className="page-content">
                                    <p>Sorry, but nothing matched your search terms. Please try again with some different keywords.</p>
                                    <form role="search" method="get" className="search-form">
                                        <label>
                                            <span className="screen-reader-text">Search for:</span>
                                            <input type="search" className="search-field" placeholder="Search..." value={this.props.searchInputValue} onChange={e => this.props.setSearchInputValue(e.target.value.trim())} />
                                        </label>
                                        <button type="button" className="search-submit" onClick={ev => this.initiateGetSearchResultsForSearchInput()}>Search</button>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // ===============================================================================

    private renderSearchResult(declareLabelThumbnail: DeclareLabelThumbnail) {
        const pathToProductDetail = `/products/${declareLabelThumbnail.uniqueUrlName}`;

        return (
            <article key={declareLabelThumbnail.declareLabelId} className="declare type-declare status-publish hentry">
                <header className="entry-header">
                    <div className="pull-right hidden-xs">
                        <Link className="image-primary fsr-holder" to={pathToProductDetail} style={{ backgroundImage: "url(" + declareLabelThumbnail.productImageUrl + ")" }}>
                            <img src={declareLabelThumbnail.productImageUrl} className="image-full sr-only" />
                        </Link>
                    </div>
                    <h6><Link to={pathToProductDetail}>{declareLabelThumbnail.manufacturerName}</Link></h6>
                    <h2 className="entry-title"><Link to={pathToProductDetail} rel="bookmark">{declareLabelThumbnail.productName}</Link></h2>
			    </header>
        	    <div className="entry-summary"></div>
        	    <footer className="entry-footer"></footer>
            </article>
            );
    }

    // ===============================================================================

    private initiateGetSearchResultsForQuery() {
        let fullQueryString = this.props.location.search;

        if (fullQueryString.startsWith("?")) {
            fullQueryString = fullQueryString.substring(1);
        }

        const query = fullQueryString
            .split('&')
            .map(kvp => kvp.split('=').map(s => decodeURIComponent(s).trim().toLowerCase()))
            .filter(t => (t.length >= 2) && (t[0] === "query"))
            .map(t => t[1].trim())
            .join(" ");

        this.props.getSearchResults(query);
    }

    // ===============================================================================

    private initiateGetSearchResultsForSearchInput() {
        this.props.getSearchResults(this.props.searchInputValue);
    }
}

// ===============================================================================

export default connect(
    (state: ApplicationState) => state.declareSearchState, // Selects which state properties are merged into the component's props
    DeclareSearchStore.actionCreators // Selects which action creators are merged into the component's props
)(DeclareSearch as any);
