import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { ApplicationState } from '../store';
import * as DeclareHomeStore from '../store/DeclareHomeStore';
import './DeclareHome.css';
import '../utilities/Utilities';
import { Filter, ActiveFilter } from '../store/Models';

// ===============================================================================

// At runtime, Redux will merge together...
type DeclareHomeProps =
    DeclareHomeStore.DeclareHomeState & // ... state we've requested from the Redux store
    typeof DeclareHomeStore.actionCreators & // ... plus action creators we've requested
    RouteComponentProps;

// ===============================================================================

class DeclareHome extends React.PureComponent<DeclareHomeProps> {
    // ===============================================================================

    // This method is called when the component is first added to the document
    public componentDidMount() {
        const $window = $(window);

        $window.resize(this.window_resize);
        $window.scroll(this.window_scroll);

        this.props.loadDeclareLabelThumbnails();
        this.initializeFiltersFromUrl();

        $(".search-icon-click").click(this.searchIcon_click);
        $("#search_query").keydown(this.searchInput_keydown);
    }

    // ===============================================================================

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.props.updateVisibleProducts();

        $(".search-icon-click").click(this.searchIcon_click);
        $("#search_query").keydown(this.searchInput_keydown);
    }

    // ===============================================================================

    public componentWillUnmount() {
        const $window = $(window);

        $window.off("resize");
        $window.off("scroll");
    }

    // ===============================================================================

    public render() {
        return (
            <React.Fragment>
                <div style={{ backgroundColor: "#f2ede9" }}>
                    {this.renderHeroSection()}
                    {this.props.isLoading && <div className="container loading-message">Loading...</div>}
                    {!this.props.isLoading && this.renderDeclareLabelFiltersAndThumbnails()}
                </div>
                {this.props.sharingFilteredView && this.renderShareFilteredViewModal()}
            </React.Fragment>
        );
    }

    // ===============================================================================

    private renderShareFilteredViewModal() {
        const shareUrl = "https://declare.living-future.org/?" + this.props.activeFilters.map(filter => "filter=" + `${encodeURIComponent(filter.filterId + "|" + filter.value)}`).join("&");

        return (
            <div id="share-filtered-view-modal" className="modal label-image-modal in" role="dialog" style={{ display: "block" }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={ev => this.props.closeShareFilteredView()}><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">Share</h4>
                        </div>
                        <div className="modal-body">
                            <p>Copy this URL to share this filtered view:</p>
                            <div className="input-group">
                                <input type="text" style={{ height: "41px" }} className="form-control" disabled={true} value={shareUrl} />
                                <span className="input-group-btn">
                                    <button className="btn btn-default" type="button" onClick={ev => this.copyStringToClipboard(shareUrl)}>Copy</button>
                                </span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={ev => this.props.closeShareFilteredView()}>Done</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // ===============================================================================

    private renderHeroSection() {
        return (
            <div className="lf-site-hero text-center" style={{ backgroundImage: "url(https://living-future.org/wp-content/uploads/2016/11/Declare_headerimage.jpg)" }}>
                <div className="hero-inner lf-title-block">
                    <div className="container">
                        <div className="hero-block-description hero-transparency-label hero-block-transparent light-text">
                            <div className="hero-block-logo">
                                <img className="img-responsive" src="./img/declare_logo.png" alt="THE NUTRITION LABEL FOR PRODUCTS" />
                            </div>
                            <h1 className="lf-block-title block-title">THE NUTRITION LABEL FOR PRODUCTS</h1>
                            <div className="lf-block-description">Find healthy products for your next project. Declare is a transparency platform and product database that is changing the materials marketplace</div>
                            <div className="post-actions">
                                <div className="col-md-6">
                                    <a href="https://exchange.3eco.com/Account/SignUp?signUpSource=ilfi" className="btn btn-primary btn-block" target="_blank">Declare Your Product</a>
                                </div>
                                <div className="col-md-6">
                                    <a href="https://living-future.org/declare/declare-about/" target="_blank" className="btn btn-primary btn-block">Learn About Declare</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // ===============================================================================

    private renderFiltersSection() {
        const getFilterValueListItem = (filter: Filter, value: string) => {
            const isBold = ((filter.filterId === "csi-division") && value.startsWith("Division ")) ||
                ((filter.filterId === "program") && ((value === "Living Product Challenge Certified") || (value === "Declare")));
            const asterisk = (value === "LBC I-14 Responsible Sourcing") ? "*" : "";
            const doubleAsterisk = (value === "EU CoC Screened") ? "**" : "";

            var valueDescription = value;

            if ((filter.filterId === "csi-division") && value.startsWith("Division ")) {
                valueDescription = value.trimStartString("Division ");
            }

            if (filter.filterId === "countries") {
                const locationToFriendlyDescriptionMap: { [location: string]: string } = {
                    "australia": "Australia",
                    "europe": "Europe",
                    "new zealand": "New Zealand"
                };

                valueDescription = locationToFriendlyDescriptionMap[value] ?? value;
            }

            return (
                <li key={value} onClick={ev => this.activateFilter(filter.filterId, value)}>
                    {isBold
                        ? <strong>{valueDescription}</strong>
                        : valueDescription}{asterisk}{doubleAsterisk}
                </li>
            );
        }

        return (
            <div className="container lf-filter-navigation">
                <nav className="filter-inner">
                    <div className="pull-right">
                        <ul className="list-inline">
                            {this.props.filters.map(filter =>
                                <li key={filter.filterId} className="filter">
                                    <h6 onClick={ev => this.toggleFilterContent(filter.filterId)}>{filter.title}</h6>
                                    {this.props.filters.map(filter => {
                                        const itemsPerColumn = Math.max(10, Math.ceil(filter.values.length / 3.0));

                                        const numItemsInEachColumn = [itemsPerColumn, itemsPerColumn, itemsPerColumn];

                                        if (filter.filterId === "alignment") {
                                            numItemsInEachColumn[0] = 2;
                                            numItemsInEachColumn[1] = 4;
                                        }

                                        return filter.filterId === this.props.activeFilterContentId
                                            ? (
                                                <div key={`filter-options-${filter.filterId}`} className="filter_options nopadding" style={{ display: "block" }}>
                                                    {filter.filterId === "countries" &&
                                                        <div style={{ clear: "both", textAlign: "left", color: "#363645", fontSize: "12px", letterSpacing: "2px", paddingLeft: "25px", textTransform: "uppercase", paddingTop: "0px", height: "35px", marginBottom: "0" }}><strong>Declare Affiliate Products:</strong></div>}
                                                    <ul className="list-inline text-left">
                                                        <div className="col-md-4">
                                                            {filter.values.map((v, index) =>
                                                                ((index >= 0) && (index < numItemsInEachColumn[0])) &&
                                                                    getFilterValueListItem(filter, v)
                                                            )}
                                                        </div>
                                                        <div className="col-md-4">
                                                            {filter.values.map((v, index) =>
                                                                ((index >= numItemsInEachColumn[0]) && (index < (numItemsInEachColumn[0] + numItemsInEachColumn[1]))) &&
                                                                    getFilterValueListItem(filter, v)
                                                            )}
                                                        </div>
                                                        <div className="col-md-4">
                                                            {filter.values.map((v, index) =>
                                                                (index >= (numItemsInEachColumn[0] + numItemsInEachColumn[1])) &&
                                                                    getFilterValueListItem(filter, v)
                                                            )}
                                                        </div>
                                                    </ul>
                                                    {filter.filterId === "alignment" &&
                                                        <div style={{ clear: "both", textAlign: "left", color: "#888888", paddingLeft: "15px", paddingTop: "30px" }}><p>* Reporting requirement: Declare 2.0 labels processed after February 1, 2020</p><p>** Substances screened against REACH SVHC and SIN List Substances at 100 ppm at time of publication</p></div>}
                                                </div>
                                            )
                                            : null
                                    })}
                                </li>
                            )}
                            <li className="search-container">
                                <input type="text" id="search_query" name="s" className="search-input" placeholder="Search" />
                                <i id="search-icon-click" className="fa fa-search search-icon-click"></i>
                            </li>
                        </ul>
                    </div>
                </nav>
                {this.props.activeFilters.length > 0 &&
                    <div id="active-filter-container">
                        <h6>Selected Filters:</h6>
                        <ul className="list-inline" id="active-filters">
                            {this.props.activeFilters.map(activeFilter =>
                                <li key={`${activeFilter.filterId}_${activeFilter.value}`}>
                                    {activeFilter.value}
                                    <i className="fa fa-times status-close" onClick={ev => this.deactivateFilter(activeFilter.filterId, activeFilter.value)}></i>
                                </li>
                            )}
                        </ul>
                        <a id="share-filtered-view-button" role="button" onClick={ev => this.shareFilteredView_onClick(ev)}>Share filtered view</a>
                    </div>
                }
            </div>
        );
    }

    // ===============================================================================

    private renderDeclareLabelThumbnails() {
        const i_0 = Math.max(0, this.props.visibleProductsStart);
        const i_f = Math.min(this.props.filteredDeclareLabelThumbnails.length, this.props.visibleProductsEnd + 1);

        const items: JSX.Element[] = [];

        for (let index = i_0; index < i_f; ++index) {
            const thumbnail = this.props.filteredDeclareLabelThumbnails[index];

            //<div className="image-primary fsr-holder" style={{ backgroundImage: "url(" + thumbnail.productImageUrl + ")" }}>
            items.push((
                <Link key={thumbnail.declareLabelId} className="lf-card lf-reveal-card" to={`/products/${thumbnail.uniqueUrlName}`}>
                    <div className="image-primary fsr-holder" style={{ backgroundImage: "url(" + thumbnail.productImageUrl + "&t=1" + ")" }}>
                        {(thumbnail.isRedListFree || thumbnail.isLivingProductChallengeCertified) &&
                            <div className="badge-container">
                                {thumbnail.isRedListFree &&
                                    <img src="img/redlist_free.png" />}
                                {thumbnail.isLivingProductChallengeCertified &&
                                    <img src="img/lpc-sticker.png" />}
                            </div>}
                    </div>
                    <div className="post-title-wrap">
                        <div className="post-title">
                            <h6>{thumbnail.manufacturerName}</h6>
                            <h3>{thumbnail.productName.trimWordsToMaxNumChars(65)}</h3>
                        </div>
                    </div>
                </Link>
                )
            );
        }

        return (
            <div id="thumbnail-flex-box-container" className="container">
                <div style={{ height: this.props.productsSpacingTop.toString() + "px" }}></div>
                <div id="thumbnail-flex-box">
                    {(this.props.filteredDeclareLabelThumbnails.length === 0) &&
                        <div className="no-results-message-container">No results found.</div>}
                    {items}
                </div>
                <div style={{ height: this.props.productsSpacingBottom.toString() + "px" }}></div>
            </div>
        );
    }

    // ===============================================================================

    private renderDeclareLabelFiltersAndThumbnails() {
        return (
            <React.Fragment>
                {this.renderFiltersSection()}
                {this.renderDeclareLabelThumbnails()}
            </React.Fragment>
            );
    }

    // ===============================================================================

    //
    // UI Events
    //

    // ===============================================================================

    private window_resize = (e: any) => {
        this.props.updateVisibleProducts();
    }

    // ===============================================================================

    private window_scroll = (e: any) => {
        this.props.updateVisibleProducts();
    }

    // ===============================================================================

    private searchIcon_click = (e: any) => {
        // ported from site.min.js

        const $filters = $(".filter");

        const $searchInput = $(e.currentTarget).prev(".search-input");

        if ($searchInput.hasClass("open")) {
            $searchInput.removeClass("open");

            setTimeout(() => {
                $filters.fadeIn("slow");
                $(".filter-inner h6").fadeIn("slow");
            }, 500);
        }
        else {
            $filters.fadeOut("slow", () => {
                $searchInput.addClass("open")
            });

            $(".filter-inner h6").fadeOut("slow");
        }
    }

    // ===============================================================================

    private searchInput_keydown = (e: any) => {
        if (e.which === 13) {
            // enter key
            const query = ($(e.currentTarget).val() as string).trim();

            this.props.history.push(`/search?query=${encodeURIComponent(query)}`);
        }
    }

    // ===============================================================================

    private shareFilteredView_onClick = (ev: React.MouseEvent) => {
        this.props.shareFilteredView();
    }

    // ===============================================================================

    private toggleFilterContent(filterId: string) {
        if (this.props.activeFilterContentId === filterId) {
            this.props.setActiveFilterContent(null);
        }
        else {
            this.props.setActiveFilterContent(filterId);
        }
    }

    // ===============================================================================

    private activateFilter(filterId: string, filterValue: string) {
        this.props.activateFilter(filterId, filterValue);
    }

    // ===============================================================================

    private deactivateFilter(filterId: string, filterValue: string) {
        this.props.deactivateFilter(filterId, filterValue);
    }

    // ===============================================================================

    private initializeFiltersFromUrl() {
        let fullQueryString = this.props.location.search;

        if (fullQueryString.startsWith("?")) {
            fullQueryString = fullQueryString.substring(1);
        }

        const kvps = fullQueryString
            .split('&')
            .map(kvp => kvp.split('=').map(s => decodeURIComponent(s).trim()))
            .filter(t => t.length >= 2)
            .map(t => ({ key: t[0], value: t[1] }))
            .filter(kvp => kvp.key === "filter");

        const filtersFromTheQueryString: ActiveFilter[] = [];

        for (let kvp of kvps) {
            const splits = kvp.value.split("|").map(s => s.trim()).filter(s => s.length > 0);

            if (splits.length < 2) {
                continue;
            }

            const newActiveFilter: ActiveFilter = {
                filterId: splits[0],
                value: splits[1]
            }

            filtersFromTheQueryString.push(newActiveFilter);
        }

        this.props.setActiveFilters(filtersFromTheQueryString);
    }

    // ===============================================================================

    private copyStringToClipboard(shareUrl: string) {
        const textArea = document.createElement("textarea");

        textArea.setAttribute("style", "width:1px;border:0;opacity:0;");

        const $modal = $("#share-filtered-view-modal");

        $modal.append(textArea);
        textArea.value = shareUrl;
        textArea.select();
        document.execCommand('copy');
        $(textArea).remove();
    }
}

// ===============================================================================

export default connect(
    (state: ApplicationState) => state.declareHomeState,
    DeclareHomeStore.actionCreators // Selects which action creators are merged into the component's props
)(DeclareHome as any);
