// ===============================================================================

export class DeclareCsiDivisionsManager {
    public static readonly declareCsiDivisions: string[] = [
        "Division 03 Concrete",
        "03 10 00 Concrete Forming and Accessories",
        "03 20 00 Concrete Reinforcing",
        "03 30 00 Cast-in-place Concrete",
        "03 40 00 Pre-cast Concrete",
        "Division 04 Masonry",
        "04 20 00 Unit Masonry",
        "04 40 00 Stone Assemblies",
        "Division 05 Metals",
        "05 05 13 Shop-Applied Coatings for Metal",
        "05 10 00 Structural Metal Framing",
        "05 20 00 Metal Joists",
        "05 30 00 Metal Decking",
        "05 40 00 Cold Formed Metal Framing",
        "05 50 00 Metal Fabrications",
        "05 70 00 Decorative Metal",
        "Division 06 Wood, Plastics, and Composites",
        "06 10 00 Rough Carpentry",
        "06 20 00 Finish Carpentry",
        "06 40 00 Architectural Woodwork",
        "06 50 00 Structural Plastics",
        "06 60 00 Plastics Fabrications",
        "06 70 00 Structural Composites",
        "Division 07 Thermal and Moisture Protection",
        "Division 08 Openings",
        "Division 09 Finishes",
        "09 20 00 Plaster and Gypsum Board",
        "09 30 00 Tiling",
        "09 50 00 Ceilings",
        "09 62 00 Specialty Flooring",
        "09 63 00 Masonry Flooring",
        "09 64 00 Wood Flooring",
        "09 65 00 Resilient Flooring",
        "09 66 00 Terrazzo Flooring",
        "09 67 00 Fluid Applied Flooring",
        "09 68 00 Carpeting",
        "09 69 00 Access Flooring",
        "09 70 00 Wall Finishes",
        "09 80 00 Acoustic Treatment",
        "09 90 00 Painting and Coating",
        "Division 10 Specialties",
        "Division 11 Equipment",
        "Division 12 Furnishings",
        "Division 13 Special Construction",
        "Division 14 Conveying Equipment",
        "Division 21 Fire Suppression",
        "Division 22 Plumbing",
        "Division 23 HVAC",
        "Division 25 Integrated Automation",
        "Division 26 Electrical",
        "Division 27 Communications",
        "Division 28 Electronic Safety and Security",
        "Division 31 Earthwork",
        "Division 32 Exterior Improvements",
        "Division 33 Utilities",
        "33 10 00 Water Utilities",
        "33 20 00 Wells",
        "33 30 00 Sanitary Sewerage Utilities",
        "33 40 00 Storm Drainage Utilities",
        "Division 46 Water and Waste Water Equipment",
        "46 07 00 Packaged Water and Wastewater Treatment Equipment",
        "46 20 00 Water and Wastewater Preliminary Treatment Equipment",
        "46 50 00 Water and Wastewater Secondary Treatment Equipment",
        "46 60 00 Water and Wastewater Advanced Treatment Equipment",
        "Division 48 Electrical Power Generation",
        "48 14 00 Solar Energy Electrical Power Generation Equipment",
        "48 15 00 Wind Energy Electrical Power Generation Equipment"
    ];
}
