//
// The store for the state of the search page, which is a search results view.
//

import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import { DeclareLabelThumbnail } from './Models';
import { ApiDataResponse } from './ApiResponse';

// ===============================================================================

export interface DeclareSearchState {
    isLoading: boolean;
    query: string;
    searchResults: DeclareLabelThumbnail[];
    searchInputValue: string;
};

// ===============================================================================

//
// Actions
//
// These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side - effects; they just describe something that is going to happen.
// 

interface RequestSearchResultsAction {
    type: "REQUEST_SEARCH_RESULTS";
    query: string;
}

interface ReceiveSearchResultsAction {
    type: "RECEIVE_SEARCH_RESULTS";
    searchResults: DeclareLabelThumbnail[];
}

interface SetSearchInputValueAction {
    type: "SET_SEARCH_INPUT_VALUE";
    searchInputValue: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestSearchResultsAction |
    ReceiveSearchResultsAction |
    SetSearchInputValueAction;

// ===============================================================================

//
// Action Creators
//
// These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//

export const actionCreators = {
    getSearchResults: (query: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState && appState.declareSearchState) {
            fetch("api/declarelabels?query=" + encodeURIComponent(query))
                .then(response => response.json() as Promise<ApiDataResponse<DeclareLabelThumbnail[]>>)
                .then(apiDataResponse => {
                    dispatch({ type: "RECEIVE_SEARCH_RESULTS", searchResults: apiDataResponse.data });
                });

            dispatch({ type: "REQUEST_SEARCH_RESULTS", query: query });
        }
    },
    setSearchInputValue: (searchInputValue: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState && appState.declareSearchState) {
            dispatch({ type: "SET_SEARCH_INPUT_VALUE", searchInputValue: searchInputValue });
        }
    }
}

// ===============================================================================

//
// Reducer
//
// For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
//

const unloadedState: DeclareSearchState = {
    isLoading: true,
    query: "",
    searchResults: [],
    searchInputValue: ""
};

export const reducer: Reducer<DeclareSearchState> = (state: DeclareSearchState | undefined, incomingAction: Action): DeclareSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (incomingAction.type) {
        case "REQUEST_SEARCH_RESULTS":
            return {
                isLoading: true,
                query: (incomingAction as RequestSearchResultsAction).query,
                searchResults: state.searchResults,
                searchInputValue: (incomingAction as RequestSearchResultsAction).query
            };
        case "RECEIVE_SEARCH_RESULTS":
            return {
                isLoading: false,
                query: state.query,
                searchResults: (incomingAction as ReceiveSearchResultsAction).searchResults,
                searchInputValue: state.searchInputValue
            };
        case "SET_SEARCH_INPUT_VALUE":
            return {
                isLoading: state.isLoading,
                query: state.query,
                searchResults: state.searchResults,
                searchInputValue: (incomingAction as SetSearchInputValueAction).searchInputValue
            };
    }

    return state;
};
