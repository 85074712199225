import * as React from 'react';
import CommonPageHeader from './CommonPageHeader';
import CommonPageFooter from './CommonPageFooter';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <CommonPageHeader />
        {props.children}
        <CommonPageFooter />
    </React.Fragment>
);
