import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface ApplicationVersionState {
    versionString: string | null;
}

// ===============================================================================

//
// Action Creators
//
// These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//

export const actionCreators = {
};

// ===============================================================================

//
// Reducer
//
// For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
//

const unloadedState: ApplicationVersionState = { versionString: null };

export const reducer: Reducer<ApplicationVersionState> = (state: ApplicationVersionState | undefined, incomingAction: Action): ApplicationVersionState => {
    if (state === undefined) {
        return unloadedState;
    }

    return state;
};
