import * as ApplicationVersionStore from './ApplicationVersionStore';
import * as DeclareHomeStore from './DeclareHomeStore';
import * as DeclareLabelDetailStore from './DeclareLabelDetailStore';
import * as CommonPageHeaderStore from './CommonPageHeaderStore';
import * as DeclareSearchStore from './DeclareSearchStore';

// The top-level state object
export interface ApplicationState {
    applicationVersionState: ApplicationVersionStore.ApplicationVersionState | undefined;
    commonPageHeaderState: CommonPageHeaderStore.CommonPageHeaderState | undefined;
    declareHomeState: DeclareHomeStore.DeclareHomeState | undefined;
    declareLabelDetailState: DeclareLabelDetailStore.DeclareLabelDetailState | undefined;
    declareSearchState: DeclareSearchStore.DeclareSearchState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    applicationVersionState: ApplicationVersionStore.reducer,
    commonPageHeaderState: CommonPageHeaderStore.reducer,
    declareHomeState: DeclareHomeStore.reducer,
    declareLabelDetailState: DeclareLabelDetailStore.reducer,
    declareSearchState: DeclareSearchStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
