import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import $ from 'jquery';
import * as CommonPageHeaderStore from '../store/CommonPageHeaderStore';

// ===============================================================================

// At runtime, Redux will merge together...
type CommonPageHeaderProps =
    CommonPageHeaderStore.CommonPageHeaderState & // ... state we've requested from the Redux store
    typeof CommonPageHeaderStore.actionCreators; // ... plus action creators we've requested

// ===============================================================================

//
// Knows how to render and manage UI events for the common page header, e.g. the nav
// at the top of the page.
//
class CommonPageHeader extends React.PureComponent<CommonPageHeaderProps> {
    // ===============================================================================

    // This method is called when the component is first added to the document
    public componentDidMount() {
        // use Jquery events instead of React events for compatability w/ the
        // existing ported over HTML

        $("#global_search_icon").click(this.globalSearchIcon_click);
        $(".navbar-toggle").click(this.mobileNavbarToggle_click);
        $("#mobile-navbar .non-link-item").click(this.mobileNavbarNonLinkItem_click);
    }

    // ===============================================================================

    // This method is called when the route parameters change
    public componentDidUpdate() {
    }

    // ===============================================================================

    public render() {
        return (
            <header id="masthead" className="site-header navbar navbar-inverse" role="banner">
                <div className="container">
                    <div className="site-branding">
                        <h1 className="site-title sr-only">Living-Future.org</h1>
                        <a href="https://living-future.org/" rel="home">
                            <img src="./img/logo.png" />
                        </a>
                    </div>
                    <div id="my-account-navigation">
                        <ul className="account-links list-inline">
                            <li><a href="https://living-future.force.com/s/login/" target="_blank">Member Login</a></li>
                            <li><a className="cart-contents" href="https://living-future.org/cart/" title="View your shopping cart"></a></li>
                        </ul>
                    </div>
                    <nav id="site-navigation" className="primary-navigation" role="navigation">
                        <div id="navbar" className="collapse navbar-collapse">
                            <ul id="menu-primary-menu" className="nav navbar-nav navbar-right">
                                <li id="menu-item-10" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor menu-item-has-children menu-item-10 dropdown item-level-0" onMouseLeave={ev => this.props.setActiveMenu(null)} onMouseEnter={ev => this.props.setActiveMenu("programs")}>
                                    <span className="non-link-item">Programs</span>
                                    <ul role="menu" className=" dropdown-menu menu-level-1" style={{ top: "100px", display: this.props.activeMenuId === "programs" ? "block" : "none" }}>
                                        <div className="container">
                                            <li id="menu-item-395" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-395 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Living Future Challenge</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-94439" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-94439 dropdown item-level-2">
                                                        <a title="Living Building Challenge 4.0" href="https://living-future.org/lbc4/">Living Building Challenge 4.0</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-209363" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209363 item-level-3"><a title="Living Building Challenge 4.0 Basics" href="https://living-future.org/lbc4/basics4-0/">Living Building Challenge 4.0 Basics</a></li>
                                                            <li id="menu-item-400" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-400 item-level-3"><a title="Certified LBC Case Studies" href="https://living-future.org/lbc/case-studies/">Certified LBC Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-210497" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-210497 dropdown item-level-2">
                                                        <a title="Living Building Challenge 3.1" href="http://living-future.org/lbc">Living Building Challenge 3.1</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-210498" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-210498 item-level-3"><a title="Living Building Challenge 3.1 Basics" href="https://living-future.org/basics/">Living Building Challenge 3.1 Basics</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-137301" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-137301 dropdown item-level-2">
                                                        <a title="Core Green Building Certification" href="https://living-future.org/core/">Core Green Building Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-137302" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-137302 item-level-3"><a title="Core Green Building Certification Basics" href="https://living-future.org/lbc4/core-basics/">Core Green Building Certification Basics</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-47057" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47057 dropdown item-level-2">
                                                        <a title="Zero Energy" href="https://living-future.org/zero-energy/">Zero Energy</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-19757" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19757 item-level-3"><a title="Zero Energy Basics" href="https://living-future.org/zero-energy/certification/">Zero Energy Basics</a></li>
                                                            <li id="menu-item-19682" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19682 item-level-3"><a title="Certified ZE Case Studies" href="https://living-future.org/lbc/case-studies/?certs=zero-energy">Certified ZE Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-90055" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-90055 item-level-2"><a title="Zero Carbon" href="https://living-future.org/zero-carbon-certification/">Zero Carbon</a></li>
                                                    <li id="menu-item-79400" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-79400 dropdown item-level-2">
                                                        <a title="Living Product Challenge" href="https://living-future.org/lpc/">Living Product Challenge</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-47091" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47091 item-level-3"><a title="Living Product Basics" href="https://living-future.org/lpc/basics/">Living Product Basics</a></li>
                                                            <li id="menu-item-676" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-676 item-level-3"><a title="Certified LPC Case Studies" href="https://living-future.org/lpc/case-studies/">Certified LPC Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-47155" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47155 dropdown item-level-2">
                                                        <a title="Living Community Challenge" href="https://living-future.org/lcc/">Living Community Challenge</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-47169" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47169 item-level-3"><a title="Living Community Basics" href="https://living-future.org/lcc/basics/">Living Community Basics</a></li>
                                                            <li id="menu-item-47185" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47185 item-level-3"><a title="LCC Projects" href="https://living-future.org/lcc/projects/">LCC Projects</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-47297" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47297 item-level-2"><a title="Living Food Challenge | Pilot" href="https://living-future.org/lfc/">Living Food Challenge | Pilot</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-396" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-396 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Transparency Labels</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-47397" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-684 current_page_item menu-item-has-children menu-item-47397 dropdown active item-level-2"><a title="Declare Products" href="https://living-future.org/declare/">Declare Products</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-47398" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47398 item-level-3"><a title="About Declare" href="https://living-future.org/declare/declare-about/">About Declare</a></li>
                                                            <li id="menu-item-47432" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47432 item-level-3"><a title="The Red List" href="https://living-future.org/lbc/red-list/">The Red List</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-47474" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47474 dropdown item-level-2"><a title="Reveal Buildings" href="https://living-future.org/reveal-buildings/">Reveal Buildings</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-47482" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47482 item-level-3"><a title="About Reveal" href="https://living-future.org/reveal/">About Reveal</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-47546" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47546 dropdown item-level-2">
                                                        <a title="Just Organizations" href="https://living-future.org/just-overview/">Just Organizations</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-47506" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47506 item-level-3"><a title="About Just" href="https://living-future.org/just/">About Just</a></li>
                                                            <li id="menu-item-513072" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-513072 item-level-3"><a title="Just 2.0 Organizations" href="https://living-future.org/just-2-orgs/">Just 2.0 Organizations</a></li>
                                                            <li id="menu-item-47533" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47533 item-level-3"><a title="Just Case Studies" href="https://living-future.org/just-case-studies/">Just Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-397" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-397 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Initiatives</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-47806" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47806 item-level-2"><a title="Affordable Housing" href="https://living-future.org/affordable-housing/">Affordable Housing</a></li>
                                                    <li id="menu-item-47819" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47819 dropdown item-level-2"><a title="Biophilic Design" href="https://living-future.org/biophilic-design-overview/">Biophilic Design</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-47818" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47818 item-level-3"><a title="Biophilic Design Initiative" href="https://living-future.org/biophilic-design/">Biophilic Design Initiative</a></li>
                                                            <li id="menu-item-47817" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47817 item-level-3"><a title="Biophilic Design Case Studies" href="https://living-future.org/biophilic-design-case-studies/">Biophilic Design Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-47850" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47850 item-level-2"><a title="EMERGE Leadership Project" href="https://living-future.org/emerge-leadership-project/">EMERGE Leadership Project</a></li>
                                                    <li id="menu-item-26160" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-26160 item-level-2"><a title="Living Future Institute Australia" href="https://living-future.org.au/" target="_blank">Living Future Institute Australia</a></li>
                                                    <li id="menu-item-26159" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-26159 item-level-2"><a title="Living Future Institute Europe" href="https://living-future.org/living-future-europe/">Living Future Institute Europe</a></li>
                                                    <li id="menu-item-47866" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47866 item-level-2"><a title="Pittsburgh Living Product Hub" href="https://living-future.org/pittsburgh-living-product-hub/">Pittsburgh Living Product Hub</a></li>
                                                    <li id="menu-item-47846" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47846 item-level-2"><a title="Cascadia Green Building Coalition" href="https://living-future.org/cascadia-green-building-coalition/">Cascadia Green Building Coalition</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-398" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-398 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Certification &amp; Registration</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-709" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-709 dropdown item-level-2"><a title="Living Building &amp; Petal Certification" href="https://living-future.org/lbc/certification/">Living Building &amp; Petal Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-19667" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19667 item-level-3"><a title="Living Building FAQ" href="https://living-future.org/contact-us/faq/#living-building-challenge-lbc">Living Building FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-47913" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47913 dropdown item-level-2"><a title="Zero Energy Certification" href="https://living-future.org/zero-energy/certification/">Zero Energy Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-19668" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19668 item-level-3"><a title="Zero Energy FAQ" href="https://living-future.org/contact-us/faq/#net-zero-energy-building-certification">Zero Energy FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-489439" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-489439 item-level-2"><a title="Zero Carbon Certification" href="https://living-future.org/zero-carbon-certification/">Zero Carbon Certification</a></li>
                                                    <li id="menu-item-711" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-711 dropdown item-level-2"><a title="Living Product Certification" href="https://living-future.org/lpc/certification/">Living Product Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-19669" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19669 item-level-3"><a title="Living Product FAQ" href="https://living-future.org/contact-us/faq/#living-product-challenge-lpc">Living Product FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-710" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-710 dropdown item-level-2"><a title="Living Community Certification" href="https://living-future.org/lcc/certification/">Living Community Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-19670" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19670 item-level-3"><a title="Living Community FAQ" href="https://living-future.org/contact-us/faq/#living-community-challenge-lcc">Living Community FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li id="menu-item-11" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-11 dropdown item-level-0" onMouseLeave={ev => this.props.setActiveMenu(null)} onMouseEnter={ev => this.props.setActiveMenu("education-events")}>
                                    <span className="non-link-item">Education + Events</span>
                                    <ul role="menu" className=" dropdown-menu menu-level-1" style={{ top: "100px", display: this.props.activeMenuId === "education-events" ? "block" : "none" }}>
                                        <div className="container">
                                            <li id="menu-item-403" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-403 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Education</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-6441" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6441 item-level-2"><a title="Online Learning" href="https://education.living-future.org/#/public-dashboard" target="_blank">Online Learning</a></li>
                                                    <li id="menu-item-19678" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19678 item-level-2"><a title="Workshops + Webinars" href="http://living-future.org/events">Workshops + Webinars</a></li>
                                                    <li id="menu-item-84178" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84178 item-level-2"><a title="Living Future Accreditation" href="https://living-future.org/lfa/">Living Future Accreditation</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-404" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-404 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Conferences + Events</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-434577" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-434577 item-level-2"><a title="Living Future 2020" href="https://unconference.living-future.org/" target="_blank">Living Future 2020</a></li>
                                                    <li id="menu-item-299672" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-299672 item-level-2"><a title="LBC 4.0 Workshops" href="https://living-future.org/events/lbc-4-0-workshops/">LBC 4.0 Workshops</a></li>
                                                    <li id="menu-item-727" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-727 item-level-2"><a title="Other Upcoming Events" href="https://living-future.org/events/">Other Upcoming Events</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-19672" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-19672 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Research + Resources</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-679" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-679 item-level-2"><a title="Research Reports" href="https://living-future.org/research/">Research Reports</a></li>
                                                    <li id="menu-item-19675" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19675 item-level-2"><a title="LBC Resources" href="https://living-future.org/lbc/resources/">LBC Resources</a></li>
                                                    <li id="menu-item-19673" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19673 item-level-2"><a title="LPC Resources" href="https://living-future.org/lpc/resources/">LPC Resources</a></li>
                                                    <li id="menu-item-19674" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19674 item-level-2"><a title="LCC Resources" href="https://living-future.org/lcc/resources/">LCC Resources</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-405" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-405 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Publications</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-46520" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46520 item-level-2"><a title="Ecotone Bookstore" href="https://living-future.org/bookstore/">Ecotone Bookstore</a></li>
                                                    <li id="menu-item-737" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-737 dropdown item-level-2">
                                                        <a title="Trim Tab + Blog" href="http://trimtab.living-future.org/" target="_blank">Trim Tab + Blog</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-738" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-738 item-level-3"><a title="Latest Articles" href="https://trimtab.living-future.org/category/trim-tab/" target="_blank">Latest Articles</a></li>
                                                            <li id="menu-item-739" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-739 item-level-3"><a title="Magazine Archive" href="https://trimtab.living-future.org/archive/" target="_blank">Magazine Archive</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li id="menu-item-15" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-15 dropdown item-level-0" onMouseLeave={ev => this.props.setActiveMenu(null)} onMouseEnter={ev => this.props.setActiveMenu("about-us")}>
                                    <span className="non-link-item">About Us</span>
                                    <ul role="menu" className=" dropdown-menu menu-level-1" style={{ top: "100px", display: this.props.activeMenuId === "about-us" ? "block" : "none" }}>
                                        <div className="container">
                                            <li id="menu-item-735" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-735 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">About Living Future</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-46206" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46206 item-level-2"><a title="Programs Overview" href="https://living-future.org/programs-overview/">Programs Overview</a></li>
                                                    <li id="menu-item-46210" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46210 item-level-2"><a title="Our Impact" href="https://living-future.org/our-impact/">Our Impact</a></li>
                                                    <li id="menu-item-741" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-741 item-level-2"><a title="Frequently Asked Questions" href="https://living-future.org/contact-us/faq/">Frequently Asked Questions</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-19676" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-19676 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Our Team</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-46247" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46247 item-level-2"><a title="People" href="https://living-future.org/people/">People</a></li>
                                                    <li id="menu-item-46254" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46254 item-level-2"><a title="Board of Directors" href="https://living-future.org/board/">Board of Directors</a></li>
                                                    <li id="menu-item-46259" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46259 item-level-2"><a title="Sponsors" href="https://living-future.org/sponsors/">Sponsors</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-736" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-736 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Learn More</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-46140" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46140 item-level-2"><a title="Careers" href="https://living-future.org/careers/">Careers</a></li>
                                                    <li id="menu-item-46287" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46287 item-level-2"><a title="Media Resources" href="https://living-future.org/media-resources/">Media Resources</a></li>
                                                    <li id="menu-item-740" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-740 item-level-2"><a title="Contact Us" href="https://living-future.org/contact-us/">Contact Us</a></li>
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li id="menu-item-16" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-16 dropdown item-level-0" onMouseLeave={ev => this.props.setActiveMenu(null)} onMouseEnter={ev => this.props.setActiveMenu("get-involved")}>
                                    <span className="non-link-item">Get Involved</span>
                                    <ul role="menu" className=" dropdown-menu menu-level-1" style={{ top: "100px", display: this.props.activeMenuId === "get-involved" ? "block" : "none" }}>
                                        <div className="container">
                                            <li id="menu-item-771" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-771 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Join Living Future</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-45972" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-45972 dropdown item-level-2">
                                                        <a title="Become a Member" href="https://living-future.org/membership/">Become a Member</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li id="menu-item-19677" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19677 item-level-3"><a title="Membership FAQ" href="https://living-future.org/contact-us/faq/#membership">Membership FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                    <li id="menu-item-23461" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23461 item-level-2"><a title="Living Future Network" href="https://living-future.org/network/">Living Future Network</a></li>
                                                    <li id="menu-item-46005" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46005 item-level-2"><a title="Policy + Advocacy" href="https://living-future.org/policy-advocacy/">Policy + Advocacy</a></li>
                                                    <li id="menu-item-46071" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46071 item-level-2"><a title="Become a Sponsor" href="https://living-future.org/become-a-sponsor/">Become a Sponsor</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-772" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-772 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Newsletter + Social</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li id="menu-item-46098" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46098 item-level-2"><a title="Newsletter + Social" href="https://living-future.org/newsletter/">Newsletter + Social</a></li>
                                                    <li id="menu-item-46113" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46113 item-level-2"><a title="Social Media Kit" href="https://living-future.org/socialkit/">Social Media Kit</a></li>
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li id="menu-item-31255" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-31255 item-level-0"><a className="menu-callout-button" title="DONATE" href="https://living-future.org/donate/">DONATE</a></li>
                                <li id="global_search_container">
                                    <form id="global_search_form" action="https://living-future.org/" method="get" role="search">
                                        <input type="text" id="global_search_query" name="s" className="global-search-input" placeholder="Search" /><i id="global_search_icon" className="fa fa-search"></i>
                                    </form>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <nav id="mobile-navigation" className="mobile-navigation" role="navigation">
                        <div className="navbar-controls">
                            <button type="button" className="navbar-toggle"></button>
                        </div>
                        <div id="mobile-navbar" className="mobile-nav-container">
                            <ul id="menu-primary-menu-1" className="mobile-nav">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor menu-item-has-children menu-item-10 dropdown item-level-0" style={{ "display": "list-item" }}>
                                    <span className="non-link-item">Programs</span>
                                    <ul role="menu" className=" dropdown-menu menu-level-1">
                                        <div className="container">
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-395 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Living Future Challenge</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-94439 dropdown item-level-2">
                                                        <a title="Living Building Challenge 4.0" href="https://living-future.org/lbc4/">Living Building Challenge 4.0</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209363 item-level-3"><a title="Living Building Challenge 4.0 Basics" href="https://living-future.org/lbc4/basics4-0/">Living Building Challenge 4.0 Basics</a></li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-400 item-level-3"><a title="Certified LBC Case Studies" href="https://living-future.org/lbc/case-studies/">Certified LBC Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-210497 dropdown item-level-2">
                                                        <a title="Living Building Challenge 3.1" href="http://living-future.org/lbc">Living Building Challenge 3.1</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-210498 item-level-3"><a title="Living Building Challenge 3.1 Basics" href="https://living-future.org/basics/">Living Building Challenge 3.1 Basics</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-137301 dropdown item-level-2">
                                                        <a title="Core Green Building Certification" href="https://living-future.org/core/">Core Green Building Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-137302 item-level-3"><a title="Core Green Building Certification Basics" href="https://living-future.org/lbc4/core-basics/">Core Green Building Certification Basics</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47057 dropdown item-level-2">
                                                        <a title="Zero Energy" href="https://living-future.org/zero-energy/">Zero Energy</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19757 item-level-3"><a title="Zero Energy Basics" href="https://living-future.org/zero-energy/certification/">Zero Energy Basics</a></li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19682 item-level-3"><a title="Certified ZE Case Studies" href="https://living-future.org/lbc/case-studies/?certs=zero-energy">Certified ZE Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-90055 item-level-2">
                                                        <a title="Zero Carbon" href="https://living-future.org/zero-carbon-certification/">Zero Carbon</a>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-79400 dropdown item-level-2">
                                                        <a title="Living Product Challenge" href="https://living-future.org/lpc/">Living Product Challenge</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47091 item-level-3"><a title="Living Product Basics" href="https://living-future.org/lpc/basics/">Living Product Basics</a></li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-676 item-level-3"><a title="Certified LPC Case Studies" href="https://living-future.org/lpc/case-studies/">Certified LPC Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47155 dropdown item-level-2">
                                                        <a title="Living Community Challenge" href="https://living-future.org/lcc/">Living Community Challenge</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47169 item-level-3"><a title="Living Community Basics" href="https://living-future.org/lcc/basics/">Living Community Basics</a></li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47185 item-level-3"><a title="LCC Projects" href="https://living-future.org/lcc/projects/">LCC Projects</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47297 item-level-2">
                                                        <a title="Living Food Challenge | Pilot" href="https://living-future.org/lfc/">Living Food Challenge | Pilot</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-396 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Transparency Labels</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-684 current_page_item menu-item-has-children menu-item-47397 dropdown active item-level-2">
                                                        <a title="Declare Products" href="https://living-future.org/declare/">Declare Products</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47398 item-level-3"><a title="About Declare" href="https://living-future.org/declare/declare-about/">About Declare</a></li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47432 item-level-3"><a title="The Red List" href="https://living-future.org/lbc/red-list/">The Red List</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47474 dropdown item-level-2">
                                                        <a title="Reveal Buildings" href="https://living-future.org/reveal-buildings/">Reveal Buildings</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47482 item-level-3"><a title="About Reveal" href="https://living-future.org/reveal/">About Reveal</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47546 dropdown item-level-2">
                                                        <a title="Just Organizations" href="https://living-future.org/just-overview/">Just Organizations</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47506 item-level-3"><a title="About Just" href="https://living-future.org/just/">About Just</a></li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-513072 item-level-3"><a title="Just 2.0 Organizations" href="https://living-future.org/just-2-orgs/">Just 2.0 Organizations</a></li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47533 item-level-3"><a title="Just Case Studies" href="https://living-future.org/just-case-studies/">Just Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-397 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Initiatives</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47806 item-level-2"><a title="Affordable Housing" href="https://living-future.org/affordable-housing/">Affordable Housing</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47819 dropdown item-level-2">
                                                        <a title="Biophilic Design" href="https://living-future.org/biophilic-design-overview/">Biophilic Design</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47818 item-level-3"><a title="Biophilic Design Initiative" href="https://living-future.org/biophilic-design/">Biophilic Design Initiative</a></li>
                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47817 item-level-3"><a title="Biophilic Design Case Studies" href="https://living-future.org/biophilic-design-case-studies/">Biophilic Design Case Studies</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47850 item-level-2"><a title="EMERGE Leadership Project" href="https://living-future.org/emerge-leadership-project/">EMERGE Leadership Project</a></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-26160 item-level-2"><a title="Living Future Institute Australia" href="https://living-future.org.au/" target="_blank">Living Future Institute Australia</a></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-26159 item-level-2"><a title="Living Future Institute Europe" href="https://living-future.org/living-future-europe/">Living Future Institute Europe</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47866 item-level-2"><a title="Pittsburgh Living Product Hub" href="https://living-future.org/pittsburgh-living-product-hub/">Pittsburgh Living Product Hub</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-47846 item-level-2"><a title="Cascadia Green Building Coalition" href="https://living-future.org/cascadia-green-building-coalition/">Cascadia Green Building Coalition</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-398 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Certification &amp; Registration</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-709 dropdown item-level-2">
                                                        <a title="Living Building &amp; Petal Certification" href="https://living-future.org/lbc/certification/">Living Building &amp; Petal Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19667 item-level-3"><a title="Living Building FAQ" href="https://living-future.org/contact-us/faq/#living-building-challenge-lbc">Living Building FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-47913 dropdown item-level-2">
                                                        <a title="Zero Energy Certification" href="https://living-future.org/zero-energy/certification/">Zero Energy Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19668 item-level-3"><a title="Zero Energy FAQ" href="https://living-future.org/contact-us/faq/#net-zero-energy-building-certification">Zero Energy FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-489439 item-level-2"><a title="Zero Carbon Certification" href="https://living-future.org/zero-carbon-certification/">Zero Carbon Certification</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-711 dropdown item-level-2">
                                                        <a title="Living Product Certification" href="https://living-future.org/lpc/certification/">Living Product Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19669 item-level-3"><a title="Living Product FAQ" href="https://living-future.org/contact-us/faq/#living-product-challenge-lpc">Living Product FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-710 dropdown item-level-2">
                                                        <a title="Living Community Certification" href="https://living-future.org/lcc/certification/">Living Community Certification</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19670 item-level-3"><a title="Living Community FAQ" href="https://living-future.org/contact-us/faq/#living-community-challenge-lcc">Living Community FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-11 dropdown item-level-0" style={{ "display": "list-item" }}>
                                    <span className="non-link-item">Education + Events</span>
                                    <ul role="menu" className=" dropdown-menu menu-level-1">
                                        <div className="container">
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-403 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Education</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6441 item-level-2"><a title="Online Learning" href="https://education.living-future.org/#/public-dashboard" target="_blank">Online Learning</a></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19678 item-level-2"><a title="Workshops + Webinars" href="http://living-future.org/events">Workshops + Webinars</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-84178 item-level-2"><a title="Living Future Accreditation" href="https://living-future.org/lfa/">Living Future Accreditation</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-404 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Conferences + Events</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-434577 item-level-2"><a title="Living Future 2020" href="https://unconference.living-future.org/" target="_blank">Living Future 2020</a></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-299672 item-level-2"><a title="LBC 4.0 Workshops" href="https://living-future.org/events/lbc-4-0-workshops/">LBC 4.0 Workshops</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-727 item-level-2"><a title="Other Upcoming Events" href="https://living-future.org/events/">Other Upcoming Events</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-19672 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Research + Resources</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-679 item-level-2"><a title="Research Reports" href="https://living-future.org/research/">Research Reports</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19675 item-level-2"><a title="LBC Resources" href="https://living-future.org/lbc/resources/">LBC Resources</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19673 item-level-2"><a title="LPC Resources" href="https://living-future.org/lpc/resources/">LPC Resources</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19674 item-level-2"><a title="LCC Resources" href="https://living-future.org/lcc/resources/">LCC Resources</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-405 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Publications</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-528767 item-level-2"><a title="Ecotone Bookstore" href="https://store.living-future.org" target="_blank">Ecotone Bookstore</a></li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-737 dropdown item-level-2">
                                                        <a title="Trim Tab + Blog" href="http://trimtab.living-future.org/" target="_blank">Trim Tab + Blog</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-738 item-level-3"><a title="Latest Articles" href="https://trimtab.living-future.org/category/trim-tab/" target="_blank">Latest Articles</a></li>
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-739 item-level-3"><a title="Magazine Archive" href="https://trimtab.living-future.org/archive/" target="_blank">Magazine Archive</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-15 dropdown item-level-0" style={{ "display": "list-item" }}><span className="non-link-item">About Us</span>
                                    <ul role="menu" className=" dropdown-menu menu-level-1">
                                        <div className="container">
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-735 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">About Living Future</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46206 item-level-2"><a title="Programs Overview" href="https://living-future.org/programs-overview/">Programs Overview</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46210 item-level-2"><a title="Our Impact" href="https://living-future.org/our-impact/">Our Impact</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-741 item-level-2"><a title="Frequently Asked Questions" href="https://living-future.org/contact-us/faq/">Frequently Asked Questions</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-19676 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Our Team</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46247 item-level-2"><a title="People" href="https://living-future.org/people/">People</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46254 item-level-2"><a title="Board of Directors" href="https://living-future.org/board/">Board of Directors</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46259 item-level-2"><a title="Sponsors" href="https://living-future.org/sponsors/">Sponsors</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-736 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Learn More</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46140 item-level-2"><a title="Careers" href="https://living-future.org/careers/">Careers</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46287 item-level-2"><a title="Media Resources" href="https://living-future.org/media-resources/">Media Resources</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-740 item-level-2"><a title="Contact Us" href="https://living-future.org/contact-us/">Contact Us</a></li>
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-16 dropdown item-level-0" style={{ "display": "list-item" }}>
                                    <span className="non-link-item">Get Involved</span>
                                    <ul role="menu" className=" dropdown-menu menu-level-1">
                                        <div className="container">
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-771 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Join Living Future</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-45972 dropdown item-level-2">
                                                        <a title="Become a Member" href="https://living-future.org/membership/">Become a Member</a>
                                                        <ul role="menu" className=" dropdown-menu menu-level-3">
                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19677 item-level-3"><a title="Membership FAQ" href="https://living-future.org/contact-us/faq/#membership">Membership FAQ</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23461 item-level-2"><a title="Living Future Network" href="https://living-future.org/network/">Living Future Network</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46005 item-level-2"><a title="Policy + Advocacy" href="https://living-future.org/policy-advocacy/">Policy + Advocacy</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46071 item-level-2"><a title="Become a Sponsor" href="https://living-future.org/become-a-sponsor/">Become a Sponsor</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-772 dropdown item-level-1 col-md-3">
                                                <span className="non-link-item">Newsletter + Social</span>
                                                <ul role="menu" className=" dropdown-menu menu-level-2">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46098 item-level-2"><a title="Newsletter + Social" href="https://living-future.org/newsletter/">Newsletter + Social</a></li>
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-46113 item-level-2"><a title="Social Media Kit" href="https://living-future.org/socialkit/">Social Media Kit</a></li>
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-31255 item-level-0" style={{ "display": "list-item" }}>
                                    <a className="menu-callout-button" title="DONATE" href="https://living-future.org/donate/">DONATE</a>
                                </li>
                                <li className="item-level-0" style={{ "display": "list-item" }}><a href="https://living-future.force.com/LivingFutureCommunity/s/" target="_blank">Login</a></li>
                                <li className="item-level-0" style={{ "display": "list-item" }}><a className="cart-contents" href="https://living-future.org/cart/" title="View your shopping cart"></a></li>
                                <li id="mobile_search_container">
                                    <form id="mobile_search_form" action="https://living-future.org/" method="get" role="search">
                                        <input type="text" id="mobile_search_query" name="s" className="mobile-search-input" placeholder="Search" /><i id="mobile_search_icon" className="fa fa-search"></i>
                                    </form>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }

    // ===============================================================================

    //
    // UI Events
    //

    private globalSearchIcon_click = (e: any) => {
        // ported from ILFI's site.min.js

        const $item = $(".item-level-0");

        if ($item.length !== 0) {
            if ($(".global-search-input").hasClass("open")) {
                $(".global-search-input").removeClass("open");
                setTimeout(function () {
                    $item.fadeIn("slow");
                }, 500);
            }
            else {
                $item.fadeOut("slow", function () {
                    $(".global-search-input").addClass("open");
                });
            }
        }
    }

    // ===============================================================================

    private mobileNavbarToggle_click = (e: any) => {
        // ported from ILFI's site.min.js

        $(e.currentTarget).toggleClass("open");
        $("#mobile-navbar").slideToggle();
    }

    // ===============================================================================

    private mobileNavbarNonLinkItem_click = (e: any) => {
        // ported from ILFI's site.min.js

        const $this = $(e.currentTarget);

        $this.toggleClass("open");
        $this.next("ul").slideToggle();
    }
}

// ===============================================================================

export default connect(
    (state: ApplicationState) => state.commonPageHeaderState, // Selects which state properties are merged into the component's props
    CommonPageHeaderStore.actionCreators // Selects which action creators are merged into the component's props
)(CommonPageHeader as any);
