//
// Models used across the various views.
//

export enum DeclareStatus {
    RedListFree = "RedListFree",
    RedListApproved = "RedListApproved",
    Declared = "Declared"
}

export enum EnterpriseGreenCommunityStandardsCompliance {
    NoAnswer = "NoAnswer",
    Complies = "Complies",
    Exceeds = "Exceeds",
    DoesNotComply = "DoesNotComply"
}

export interface DeclareLabelThumbnail {
    declareLabelId: string;
    uniqueUrlName: string;
    productName: string;
    manufacturerName: string;
    csiDivision: string;
    csiSubdivision: string;
    productImageUrl: string;
    isRedListFree: boolean;
    declarationStatus: DeclareStatus;
    isThirdPartyVerified: boolean;
    isLeedOption1: boolean;
    isLeedOption2: boolean;
    isEmbodiedCarbonCompleted: boolean;
    isResponsibleSourcingEnabled: boolean;
    isHealthyInteriorPerformanceEnabled: boolean;
    isLivingProductChallengeCertified: boolean;
    isLbcCompliant: boolean;
    isEuChemicalOfConcernScreened: boolean;
    enterpriseGreenCommunityStandardsCompliance: EnterpriseGreenCommunityStandardsCompliance;
    location: string;
}

export interface Filter {
    filterId: string;
    title: string;
    values: string[];
}

export interface ActiveFilter {
    filterId: string;
    value: string;
}

export interface DeclareLabelImage {
    language: string;
    url: string;
}

export interface DeclareLabelLocation {
    city: string;
    state: string;
    country: string;
    ilfiDeclareId: string | null;
    labelImages: DeclareLabelImage[] | null;
}

export enum RedListStatus {
    Black = "black",
    Orange = "orange",
    Red = "red"
}

export interface DeclareLabelSubstance {
    casrn: string;
    chemicalName: string;
    percentage: string;
    redListStatus: RedListStatus;
    ecNumbers: string[];
    exception: string;
    onEuReachSvhcCandidateList: boolean;
    onEuChemSecSinList: boolean;
}

export interface DeclareLabelMaterial {
    name: string;
    substances: DeclareLabelSubstance[];
}

export interface DeclareLabel {
    declareLabelUniqueId: string;
    ilfiDeclareId: string;
    declareVersion: string;
    productName: string;
    productImageUrl: string;
    productWebsiteUrl: string;
    description: string;
    manufacturerName: string;
    manufacturerAddress: string;
    manufacturerCity: string;
    manufacturerState: string;
    manufacturerZip: string;
    manufacturerCountry: string;
    manufacturerPhone: string;
    manufacturerEmail: string;
    manufacturerImageUrl: string;
    lastUpdatedTime: string,
    expiresTime: string,
    lifeExpectancyInYears: string;
    declarationStatus: string;
    cdphStandardMethod: string;
    cdphStandardMethodFull: string;
    lbcExceptions: string;
    vocIsWetApplied: boolean;
    vocContent: string;
    vocIsEmitting: string;
    vocEmissions: string;
    thirdPartyVerified: boolean;
    assessor: string;
    livingProductChallengeCertified: boolean;
    lpcTransparentMaterialHealthImperative: boolean;
    declareInternational: string;
    endOfLifeOptions: string;
    csiDivisions: string[];
    labelImageUrls: string[];
    locations: DeclareLabelLocation[];
    locationsSummary: string | null;
    responsibleSourcingIsWood: string | null;
    responsibleSourcingCompliance: string | null;
    lcaOrEpdCompleted: boolean | null;
    embodiedCarbon: string | null;
    embodiedCarbonIndicator: string | null;
    embodiedCarbonFunctionalUnit: string | null;
    embodiedCarbonFileUrl: string | null;
    declareCarbonAssessment: string | null;
    lpcAssessmentUrl: string;
    isEuChemicalsOfConcernScreened: boolean;
    enterpriseGreenCommunityStandardsCompliance: EnterpriseGreenCommunityStandardsCompliance;
    labelImages: DeclareLabelImage[] | null;
    materials: DeclareLabelMaterial[];
}
