import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import DeclareHome from './components/DeclareHome';
import DeclareLabelDetail from './components/DeclareLabelDetail';
import DeclareSearch from './components/DeclareSearch';

//
// Render the app - use the Layout component as a template, and fill in the
// body of the layout with a component as a function of the route.
//

export default () => (
    <Layout>
        <Route exact path='/' component={DeclareHome} />
        <Route path='/products/:declareLabelNameId' component={DeclareLabelDetail} />
        <Route path='/search' component={DeclareSearch} />
    </Layout>
);
