import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { ApiDataResponse } from './ApiResponse';
import '../utilities/Utilities';

// ===============================================================================

//
// State
//
// This defines the data maintained in the Redux store.
//

export interface CommonPageHeaderState {
    activeMenuId: string | null;
};

// ===============================================================================

//
// Actions
//
// These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side - effects; they just describe something that is going to happen.
// 

interface SetActiveMenuAction {
    type: "SET_ACTIVE_MENU";
    menuId: string | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = SetActiveMenuAction;

// ===============================================================================

//
// Action Creators
//
// These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//

export const actionCreators = {
    //
    // Sets the active nav menu that should be displayed, or, if menuId is null, closes the menu.
    //
    setActiveMenu: (menuId: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState &&
            appState.commonPageHeaderState) {
            dispatch({ type: "SET_ACTIVE_MENU", menuId: menuId });
        }
    }
};

// ===============================================================================

//
// Reducer
//
// For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
//

const unloadedState: CommonPageHeaderState = { activeMenuId: null };

export const reducer: Reducer<CommonPageHeaderState> = (state: CommonPageHeaderState | undefined, incomingAction: Action): CommonPageHeaderState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (incomingAction.type) {
        case "SET_ACTIVE_MENU":
            return {
                activeMenuId: (incomingAction as SetActiveMenuAction).menuId
            };
    }

    return state;
};
