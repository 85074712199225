import * as React from 'react';

export default (props: { }) => (
    <footer id="colophon" className="site-footer" role="contentinfo">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="lf-footer-menu">
                        <div className="navbar-footer">
                            <ul id="menu-footer" className="nav-footer list-unstyled">
                                <li id="menu-item-796" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-796"><a href="https://trimtab.living-future.org/" target="_blank">Trim Tab Blog</a></li>
                                <li id="menu-item-3407" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3407"><a href="https://living-future.org/bookstore/">Bookstore</a></li>
                                <li id="menu-item-354" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-354"><a href="https://living-future.org/membership/">Become a Member</a></li>
                                <li id="menu-item-795" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-795"><a href="https://living-future.org/donateold98122/">Donate</a></li>
                                <li id="menu-item-3321" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3321"><a href="https://living-future.org/contact-us/">Contact Us</a></li>
                                <li id="menu-item-26268" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26268"><a href="https://living-future.org/terms-of-use/">Terms Of Use</a></li>
                                <li id="menu-item-26269" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26269"><a href="https://living-future.org/privacy-notice/">Privacy Notice</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="pull-right lf-ite-social-links">
                        <ul className="list-inline">
                            <li>
                                <a href="https://facebook.com/livingfutureinstitute/" title="Facebook" target="_blank">
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-circle fa-stack-2x fa-inverse"></i>
                                        <i className="fa fa-facebook fa-stack-1x"></i>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/Living_Future" title="Twitter" target="_blank">
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-circle fa-stack-2x fa-inverse"></i>
                                        <i className="fa fa-twitter fa-stack-1x"></i>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://linkedin.com/company/international-living-future-institute" title="LinkedIn" target="_blank">
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-circle fa-stack-2x fa-inverse"></i>
                                        <i className="fa fa-linkedin fa-stack-1x"></i>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/living_future/" title="Instagram" target="_blank">
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-circle fa-stack-2x fa-inverse"></i>
                                        <i className="fa fa-instagram fa-stack-1x"></i>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <small className="copyright text-center">
                Copyright © 2019 International Living Future Institute. All Rights Reserved. 				 <img src="./img/guidestarput-platinum2018-seal.png" alt="Guide Star" style={{ width: "42px", height: "42px", border: "0" }} />
            </small>
        </div>
	</footer>
);
