import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import $ from 'jquery';
import { ApplicationState } from '../store';
import { DeclareLabel, EnterpriseGreenCommunityStandardsCompliance, RedListStatus } from '../store/Models';
import * as DeclareLabelDetailStore from '../store/DeclareLabelDetailStore';
import './DeclareLabelDetail.css';

// ===============================================================================

// At runtime, Redux will merge together...
type DeclareLabelDetailProps =
    DeclareLabelDetailStore.DeclareLabelDetailState & // ... state we've requested from the Redux store
    typeof DeclareLabelDetailStore.actionCreators & // ... plus action creators we've requested
    RouteComponentProps<{ declareLabelNameId: string }>;

// ===============================================================================

class DeclareLabelDetail extends React.PureComponent<DeclareLabelDetailProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.loadDataIfNeeded();
    }

    // ===============================================================================

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.loadDataIfNeeded();
    }

    // ===============================================================================

    public render() {
        return (
            <div>
                {this.props.declareLabel === null && <div className="container" style={{ paddingTop: "40px", paddingBottom: "40px" }}><div className="row"><div className="col-md-12">Loading...</div></div></div>}
                {this.props.declareLabel !== null && this.renderDeclareLabel(this.props.declareLabel)}
            </div>
        );
    }

    // ===============================================================================

    private renderDeclareLabel(declareLabel: DeclareLabel) {
        const expiresDate = new Date(declareLabel.expiresTime);

        const declarationStatusToFriendlyDescriptionMap: { [s: string]: string } = {
            "declared": "Declared",
            "red_list_approved": "LBC Red List Approved",
            "red_list_free": "LBC Red List Free"
        };

        const declareCarbonAssessmentToDescriptionMap: { [s: string]: string } = {
            "above_average": "Above Baseline",
            "average": "Baseline Comparable",
            "below_average": "Below Baseline",
            "no_average": "Not Applicable"
        };

        let responsibleSourcingDescription = "Not Applicable";

        if (declareLabel.responsibleSourcingIsWood?.toLowerCase() === "yes") {
            responsibleSourcingDescription = (declareLabel.responsibleSourcingCompliance ?? "").nullOrEmptyCoalesce("Not Compliant");
        }

        let healthyInteriorPerformanceDescription = "Not Applicable";

        const legacyVocEmissionsValuesToNewValueMap: { [legacyValue: string]: string } = {
            "0": "Not Applicable",
            "cdph standard method v1.1 compliant": "CDPH Standard Method v1.1-2010",
            "cdph compliant": "CDPH Standard Method v1.1-2010",
            "agbb scheme compliant": "AgBB Scheme 2009"
        };

        var normalizedVocEmissionsValue = legacyVocEmissionsValuesToNewValueMap[declareLabel.vocEmissions.toLowerCase()] ?? declareLabel.vocEmissions;

        if ((declareLabel.vocIsEmitting.toLowerCase() === "yes") ||
            ((declareLabel.vocIsEmitting.length === 0) &&
                ((normalizedVocEmissionsValue.length > 0) && (normalizedVocEmissionsValue !== "Not Applicable")))) {
            healthyInteriorPerformanceDescription = normalizedVocEmissionsValue.nullOrEmptyCoalesce("Not Compliant");
        }

        let lbcComplianceDescription = "";

        if (((declareLabel.declarationStatus === "red_list_free") || (declareLabel.declarationStatus === "red_list_approved")) &&
            ((declareLabel.responsibleSourcingIsWood?.toLowerCase() === "no") ||
                ((declareLabel.responsibleSourcingIsWood?.toLowerCase() === "yes") &&
                    ((declareLabel.responsibleSourcingCompliance?.toLowerCase() ?? "").indexOf("not compliant") === -1))) &&
            ((declareLabel.vocIsEmitting.toLowerCase() === "no") ||
                ((declareLabel.vocIsEmitting.toLowerCase() === "yes") &&
                    (declareLabel.vocEmissions.toLowerCase().nullOrEmptyCoalesce("not compliant") !== "not compliant")))) {
            lbcComplianceDescription = " Compliant";
        }

        const exceptions = declareLabel.materials
            .selectMany(m => m.substances.map(s => s.exception))
            .filter(e => e.length > 0)
            .distinct();

        const lifeExpectancyDescription = declareLabel.lifeExpectancyInYears
            .trimEndString("years")
            .trimEndString("year(s)")
            .trimEndString("Years")
            .trimEndString("Year(s)")
            .trimEndString("year")
            .trimEndString("Year")
            .trim();

        return (
            <React.Fragment>
                <div className="container">
                    {this.props.history.length > 1 &&
                        <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-12">
                                <button type="button" className="btn btn-default" onClick={(evt) => { this.props.history.goBack(); }}>Back</button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-4">
                            <div className="lf-sidebar-static">
                                {declareLabel.productImageUrl.length > 0 &&
                                    <div className="image-primary lf-featured-image lf-sidebar-image fsr-holder" style={{ backgroundImage: "url(" + declareLabel.productImageUrl + "&t=1" + ")" }}>
                                        <img src={declareLabel.productImageUrl + "&t=1"} alt={declareLabel.productName} className="image-full sr-only" />
                                    </div>}
                                {declareLabel.labelImageUrls.map(imageUrl => this.renderLabelImage(imageUrl))}
                            </div>
                        </div>
                        <div className="col-md-8 lf-content-parent">
                            <div className="lf-content lf-content-declare">
                                <header className="single-page-header declare-page-header">
                                    <h6>{declareLabel.manufacturerName}</h6>
                                    <h3>{declareLabel.productName}</h3>
                                </header>
                                <div>
                                    <p>{declareLabel.description}</p>
                                    {(declareLabel.productWebsiteUrl.length > 0) &&
                                        <p><a className="product-website-link" href={declareLabel.productWebsiteUrl} target="_blank">{declareLabel.productWebsiteUrl}</a></p>}
                                </div>
                                <div className="declare-feature-list">
                                    <div className="declare-option">
                                        <label>Declare ID</label>
                                        <span>{declareLabel.ilfiDeclareId}</span>
                                    </div>
                                    <div className="declare-option">
                                        <label>License Expiration</label>
                                        <span>{expiresDate.toLocaleDateString("en-US", { day: "2-digit", timeZone: "UTC" })} {expiresDate.toLocaleDateString("en-US", { month: "short", timeZone: "UTC" })} {expiresDate.toLocaleDateString("en-US", { year: "numeric", timeZone: "UTC" })}</span>
                                    </div>
                                    <div className="declare-option">
                                        <label>Living Building Challenge Compliance:{lbcComplianceDescription}</label>
                                        <ul>
                                            <li>I-13 Red List (Declaration Status): {declarationStatusToFriendlyDescriptionMap[declareLabel.declarationStatus]}</li>
                                            {(declareLabel.declareVersion !== "1") &&
                                                <li>I-14 Responsible Sourcing: {responsibleSourcingDescription}</li>
                                            }
                                            <li>I-10 Healthy Interior Performance: {healthyInteriorPerformanceDescription}</li>
                                            {exceptions.length > 0 &&
                                                <li>Exceptions: {exceptions.map(e => DeclareLabelDetail.s_exceptionCodeToFriendlyDescriptionMap[e]).join(", ")}</li>
                                            }
                                        </ul>
                                    </div>
                                    {declareLabel.thirdPartyVerified &&
                                        <div>
                                            <div className="declare-option">
                                                <label>Third Party Verified</label>
                                                <span>Yes</span>
                                            </div>
                                            <div className="declare-option">
                                                <label>Assessor</label>
                                                <span>{declareLabel.assessor}</span>
                                            </div>
                                        </div>
                                    }
                                    <div className="declare-option">
                                        <label>Living Product Challenge (LPC) Certified</label>
                                        <span>{declareLabel.livingProductChallengeCertified ? "Yes" : "No"}</span>
                                    </div>
                                    {declareLabel.lpcTransparentMaterialHealthImperative &&
                                        <div className="declare-option">
                                            <label>LPC Transparent Material Health Imperative</label>
                                            <span>Yes</span>
                                        </div>
                                    }
                                    {((declareLabel.lcaOrEpdCompleted ?? false) && ((declareLabel.embodiedCarbon ?? "").length > 0)) &&
                                        <div className="declare-option">
                                            <label>Embodied Carbon</label>
                                            <span>{declareLabel.embodiedCarbon}</span>
                                        </div>
                                    }
                                    {((declareLabel.declareCarbonAssessment ?? "").length > 0) &&
                                        <div className="declare-option">
                                            <label>Declare Carbon Assessment</label>
                                            <span>{declareCarbonAssessmentToDescriptionMap[declareLabel.declareCarbonAssessment ?? "no_average"]}</span>
                                        </div>
                                    }
                                    {((declareLabel.embodiedCarbonFunctionalUnit ?? "").length > 0) &&
                                        <div className="declare-option">
                                            <label>Declared Unit</label>
                                            <span>{declareLabel.embodiedCarbonFunctionalUnit}</span>
                                        </div>
                                    }
                                    {((declareLabel.embodiedCarbonFileUrl ?? "").length > 0) &&
                                        <div className="declare-option">
                                            <label>EPD Link</label>
                                            <span><a href={declareLabel.embodiedCarbonFileUrl ?? ""} target="_blank">{declareLabel.embodiedCarbonFileUrl}</a></span>
                                        </div>
                                    }
                                    {declareLabel.vocIsWetApplied &&
                                        <div className="declare-option">
                                            <label>VOC Content in <span className="lower">g</span>/L</label>
                                            <span>{declareLabel.vocContent.nullOrEmptyCoalesce("Not Applicable")}</span>
                                        </div>
                                    }
                                    {(declareLabel.livingProductChallengeCertified && (declareLabel.lpcAssessmentUrl.length > 0)) &&
                                        <div className="declare-option">
                                            <label>LPC Case Study</label>
                                        <span><a href={declareLabel.lpcAssessmentUrl} target="_blank">{declareLabel.lpcAssessmentUrl}</a></span>
                                        </div>}
                                    <div className="declare-option">
                                        <label>Final Assembly Location</label>
                                        <ul>
                                            {declareLabel.locations.map((location, locationIndex) =>
                                                <li key={locationIndex}>
                                                    {[location.city, location.state, location.country].filter(s => s.length > 0).join(", ")}
                                                </li>)}
                                        </ul>
                                    </div>
                                    <div className="declare-option">
                                        <label>Life Expectancy</label>
                                        <span>{lifeExpectancyDescription} Year(s)</span>
                                    </div>
                                    <div className="declare-option">
                                        <label>End of Life Options</label>
                                        <span>{declareLabel.endOfLifeOptions}</span>
                                    </div>
                                    {declareLabel.isEuChemicalsOfConcernScreened &&
                                        <div className="declare-option">
                                            <label>EU CoC Screened</label>
                                            <span>{declareLabel.materials.some(m => m.substances.some(s => s.onEuReachSvhcCandidateList || s.onEuChemSecSinList)) ? "Contains" : "Does Not Contain"}</span>
                                        </div>}
                                    {(declareLabel.enterpriseGreenCommunityStandardsCompliance !== EnterpriseGreenCommunityStandardsCompliance.NoAnswer) &&
                                        <div className="declare-option">
                                            <label>Enterprise Green Community Criteria</label>
                                            <span>
                                                {(declareLabel.enterpriseGreenCommunityStandardsCompliance === EnterpriseGreenCommunityStandardsCompliance.Complies) && "Meets Mandatory Criteria"}
                                                {(declareLabel.enterpriseGreenCommunityStandardsCompliance === EnterpriseGreenCommunityStandardsCompliance.Exceeds) && "Meets Mandatory & Optional Criteria"}
                                                {(declareLabel.enterpriseGreenCommunityStandardsCompliance === EnterpriseGreenCommunityStandardsCompliance.DoesNotComply) && "Does not comply"}
                                            </span>
                                        </div>
                                    }
                                </div>
                                <div className="lf-table-container">
                                    <div className="lf-table-header lf-table-declare-header">
                                        <h3>Ingredient List</h3>
                                    </div>
                                    <div className="table-responsive table-declare">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Component</th>
                                                    <th>Ingredient Name</th>
                                                    <th>CAS#</th>
                                                    <th>%</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {declareLabel.materials.map((material, materialIndex) =>
                                                    material.substances.map((substance, substanceIndex) =>
                                                        <tr key={`${materialIndex}-${substanceIndex}`} className={(declareLabel.isEuChemicalsOfConcernScreened && (substance.onEuReachSvhcCandidateList || substance.onEuChemSecSinList) && (substance.redListStatus === RedListStatus.Black)) ? "ingredient-status-eu-chemical-of-concern" : `ingredient-status-${substance.redListStatus.toString()}`}>
                                                            <td>{(substanceIndex === 0) && material.name}</td>
                                                            <td className="chemical-name">
                                                                {substance.chemicalName}
                                                                {(declareLabel.isEuChemicalsOfConcernScreened && (substance.onEuReachSvhcCandidateList || substance.onEuChemSecSinList)) &&
                                                                    <React.Fragment>
                                                                        <br />
                                                                        {substance.onEuReachSvhcCandidateList ? "EU REACH SVHC Candidate List" : ""}
                                                                        {(substance.onEuReachSvhcCandidateList && substance.onEuChemSecSinList) ? " - " : ""}
                                                                        {substance.onEuChemSecSinList ? "ChemSec SIN List" : ""}
                                                                    </React.Fragment>
                                                                }
                                                            </td>
                                                            <td>{substance.casrn}</td>
                                                            <td>{substance.percentage}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="declare-company-container">
                                    <h3>Company Information</h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <address className="company-address">
                                                <strong>{declareLabel.manufacturerName}</strong>
                                                {((declareLabel.manufacturerAddress.length > 0) ||
                                                    (declareLabel.manufacturerCity.length > 0) ||
                                                    (declareLabel.manufacturerState.length > 0) ||
                                                    (declareLabel.manufacturerZip.length > 0) ||
                                                    (declareLabel.manufacturerCountry.length > 0)) &&
                                                    <span className="address">
                                                        {declareLabel.manufacturerAddress}<br />
                                                        {declareLabel.manufacturerCity} {declareLabel.manufacturerState} {declareLabel.manufacturerZip}<br />
                                                        {declareLabel.manufacturerCountry}
                                                    </span>
                                                }
                                                {(declareLabel.manufacturerPhone.length > 0) &&
                                                    <span><span className="helper-text">Phone:</span> {declareLabel.manufacturerPhone}<br /></span>
                                                }
                                                {(declareLabel.manufacturerEmail.length > 0) &&
                                                    <span><span className="helper-text">Email:</span> <a href={"mailto:" + declareLabel.manufacturerEmail} target="_blank">{declareLabel.manufacturerEmail}</a> <br /></span>
                                                }
                                            </address>
                                        </div>
                                        <div className="col-md-6">
                                            <img className="img-responsive" src={declareLabel.manufacturerImageUrl} alt={declareLabel.manufacturerName} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.activeDeclareLabelImageUrl !== null && this.renderLabelImageModal(this.props.activeDeclareLabelImageUrl)}
            </React.Fragment>
        );
    }

    // ===============================================================================

    private renderLabelImage(imageUrl: string) {
        return (
            <div key={imageUrl} className="lf-label-image lf-sidebar-image" onClick={e => this.labelImage_onClick(imageUrl)}>
                <img src={imageUrl} alt="Declare Label" className="img-responsive" />
            </div>
            );
    }

    // ===============================================================================

    private renderLabelImageModal(imageUrl: string) {
        return (
            <div className="modal label-image-modal in" role="dialog" style={{ display: "block" }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={e => this.props.setActiveDeclareLabelImageUrl(null)}><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">Declare Label</h4>
                        </div>
                        <div className="modal-body">
                            <img src={imageUrl} />
                        </div>
                    </div>
                </div>
            </div>
            );
    }

    // ===============================================================================

    private loadDataIfNeeded() {
        this.props.loadDeclareLabel(this.props.match.params.declareLabelNameId);
    }

    // ===============================================================================

    private labelImage_onClick(imageUrl: string) {
        this.props.setActiveDeclareLabelImageUrl(imageUrl);
    }

    // ===============================================================================

    private static readonly s_exceptionCodeToFriendlyDescriptionMap: { [exception: string]: string } = {
        "rl_002": "RL-002 - Small Electrical Components",
        "rl_004b": "RL-004b - Proprietary Ingredients in Declare",
        "rl_008": "RL-008 - Market Transformation",
        "rl_009": "RL-009 - Formaldehyde",
        "rl_010": "RL-010 - Chromium VI in Plumbing Flush Fixtures",
        "rl_011": "RL-011 - Lead (Added)",
        "rl_022": "RL-022 - PFAS Chemicals in Building Materials",
        "rl_023": "RL-023 - Wire Sheathing Subject to NFPA 90A, NFPA 262, UL® 910",

        // legacy values, can't be selected anymore
        "e2": "I10-E2 - Small Electrical Components",
        "e4": "I10-E4 - Proprietary Ingredients <1%",
        "e6": "I10-E6 - Glass-Mat Gypsum Sheathing",
        "e7": "I10-E7 - Lead Battery Systems",
        "e8": "I10-E8 - Door Hardware",
        "e9": "I10-E9 - Phenol Formaldehyde In Mineral Wool Insulation",
        "e10": "I10-E10 - Structural Composite Wood Members",
        "e11": "I10-E11 - Composite Wood Sheet Goods",
        "e12": "I10-E12 - HFRs in Foam Insulation",
        "e13": "I10-E13 - Mercury in UV Disinfection Lamps",
        "e16": "I10-E16 - HFRs in Non-PVC Wiring",
        "e17": "I10-E17 - Plumbing",
        "e18": "I10-E18 - Commercial Water Systems",
        "e21": "I10-E21 - Chromium VI in Plumbing Flush Fixtures",
        "e22": "I10-E22 - Formaldehyde in Systems Furniture Laminate",
        "e23": "I10-E23 - Phenol Formaldehyde Polymers in Foam Board Insulation",
        "e24": "I10-E24 - Lead Products in Medical Facilities",
    };
}

// ===============================================================================

export default connect(
    (state: ApplicationState) => state.declareLabelDetailState, // Selects which state properties are merged into the component's props
    DeclareLabelDetailStore.actionCreators // Selects which action creators are merged into the component's props
)(DeclareLabelDetail as any);
