//
// The store for the state of the home page, which is a view
// of all published declare labels, along with search and filters.
//

import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import $ from 'jquery';

import { ActiveFilter, Filter, DeclareLabelThumbnail, DeclareStatus, EnterpriseGreenCommunityStandardsCompliance } from './Models';
import { ApiDataResponse } from './ApiResponse';
import { DeclareCsiDivisionsManager } from '../utilities/DeclareCsiDivisionsManager';

// ===============================================================================

//
// State
//
// This defines the data maintained in the Redux store.
//

export interface DeclareHomeState {
    isLoading: boolean;
    activeFilterContentId: string | null;
    activeFilters: ActiveFilter[];
    filters: Filter[];
    allDeclareLabelThumbnails: DeclareLabelThumbnail[];
    filteredDeclareLabelThumbnails: DeclareLabelThumbnail[];
    visibleProductsStart: number;
    visibleProductsEnd: number;
    productsSpacingTop: number;
    productsSpacingBottom: number;
    sharingFilteredView: boolean;
};

// ===============================================================================

//
// Actions
//
// These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side - effects; they just describe something that is going to happen.
// 

interface RequestDeclareLabelThumbnailsAction {
    type: "REQUEST_DECLARE_LABEL_THUMBNAILS";
}

interface ReceiveDeclareLabelThumbnailsAction {
    type: "RECEIVE_DECLARE_LABEL_THUMBNAILS";
    declareLabelThumbnails: DeclareLabelThumbnail[];
}

interface SetActiveFilterContentAction {
    type: "SET_ACTIVE_FILTER_CONTENT";
    filterId: string | null;
}

interface SetActiveFiltersAction {
    type: "SET_ACTIVE_FILTERS";
    activeFilters: ActiveFilter[];
}

interface ActivateFilterAction {
    type: "ACTIVATE_FILTER";
    filterId: string;
    filterValue: string;
}

interface DeactivateFilterAction {
    type: "DEACTIVATE_FILTER";
    filterId: string;
    filterValue: string;
}

interface UpdateVisibleProductsAction {
    type: "UPDATE_VISIBLE_PRODUCTS";
}

interface ShareFilteredViewAction {
    type: "SHARE_FILTERED_VIEW";
}

interface CloseShareFilteredViewAction {
    type: "CLOSE_SHARE_FILTERED_VIEW";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDeclareLabelThumbnailsAction |
    ReceiveDeclareLabelThumbnailsAction |
    SetActiveFilterContentAction |
    SetActiveFiltersAction |
    ActivateFilterAction |
    DeactivateFilterAction |
    UpdateVisibleProductsAction |
    ShareFilteredViewAction |
    CloseShareFilteredViewAction;

// ===============================================================================

//
// Action Creators
//
// These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//

export const actionCreators = {
    loadDeclareLabelThumbnails: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState) {
            fetch("api/declarelabels")
                .then(response => response.json() as Promise<ApiDataResponse<DeclareLabelThumbnail[]>>)
                .then(apiDataResponse => {
                    dispatch({ type: "RECEIVE_DECLARE_LABEL_THUMBNAILS", declareLabelThumbnails: apiDataResponse.data });
                });

            dispatch({ type: "REQUEST_DECLARE_LABEL_THUMBNAILS"});
        }
    },
    setActiveFilterContent: (filterId: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.declareHomeState) {
            dispatch({ type: "SET_ACTIVE_FILTER_CONTENT", filterId: filterId });
        }
    },
    setActiveFilters: (activeFilters: ActiveFilter[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.declareHomeState) {
            dispatch({ type: "SET_ACTIVE_FILTERS", activeFilters: activeFilters });
        }
    }, 
    activateFilter: (filterId: string, value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.declareHomeState) {
            dispatch({ type: "ACTIVATE_FILTER", filterId: filterId, filterValue: value });
        }
    },
    deactivateFilter: (filterId: string, value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.declareHomeState) {
            dispatch({ type: "DEACTIVATE_FILTER", filterId: filterId, filterValue: value });
        }
    },
    updateVisibleProducts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.declareHomeState) {
            dispatch({ type: "UPDATE_VISIBLE_PRODUCTS" });
        }
    },
    shareFilteredView: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.declareHomeState) {
            dispatch({ type: "SHARE_FILTERED_VIEW" });
        }
    },
    closeShareFilteredView: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.declareHomeState) {
            dispatch({ type: "CLOSE_SHARE_FILTERED_VIEW" });
        }
    },
};

// ===============================================================================

//
// Implementation functions
//

function getFiltersForThumbnails(declareLabelThumbnails: DeclareLabelThumbnail[]): Filter[] {
    // The Declare team only wants these locations to show up in the filter:
    const validLocationsForLocationFilter = ["australia", "europe", "new zealand"];

    const filters: Filter[] = [
        {
            filterId: "program",
            title: "Program",
            values: ["Living Product Challenge Certified", "Declare", "Declare + Third Party Verified", "Declare + Embodied Carbon (Pilot)"]
        },
        {
            filterId: "declaration-status",
            title: "Declaration Status",
            values: ["LBC Red List Free", "LBC Red List Approved", "Declared"]
        },
        {
            filterId: "alignment",
            title: "Alignment",
            values: [
                "LEED v4 and 4.1 BPDO Material Ingredients Option 1",
                "LEED v4.1 BPDO Material Ingredients Option 2",
                "LBC I-13 Red List",
                "LBC I-14 Responsible Sourcing",
                "LBC I-10 Healthy Interior Performance",
                "EU CoC Screened",
                "Enterprise Green Community Criteria - Meets Mandatory Criteria",
                "Enterprise Green Community Criteria - Meets Mandatory & Optional Criteria"
            ]
        },
        {
            filterId: "manufacturer",
            title: "Manufacturer",
            values: declareLabelThumbnails
                .map(t => t.manufacturerName.trim())
                .filter(s => s.length > 0)
                .distinct()
                .sort()
        },
        {
            filterId: "csi-division",
            title: "CSI Div",
            values: DeclareCsiDivisionsManager.declareCsiDivisions
        },
        {
            filterId: "countries",
            title: "Location",
            values: declareLabelThumbnails
                .map(t => t.location)
                .filter(l => validLocationsForLocationFilter.indexOf(l) !== -1)
                .distinct()
                .sort()
        }
    ];

    return filters;
}

// ===============================================================================

function applyDeclarationStatusFilter(
    thumbnail: DeclareLabelThumbnail,
    filterValue: string
) {
    switch (filterValue) {
        case "LBC Red List Free":
            return thumbnail.declarationStatus === DeclareStatus.RedListFree;
        case "LBC Red List Approved":
            return thumbnail.declarationStatus === DeclareStatus.RedListApproved;
        case "Declared":
            return thumbnail.declarationStatus === DeclareStatus.Declared;
    }

    return false;
}

// ===============================================================================

function applyProgramFilter(
    thumbnail: DeclareLabelThumbnail,
    filterValue: string
) {
    switch (filterValue) {
        case "Declare":
            return true; // all products by definition are "Declare"
        case "Declare + Third Party Verified":
            return thumbnail.isThirdPartyVerified;
        case "Declare + Embodied Carbon (Pilot)":
            return thumbnail.isEmbodiedCarbonCompleted;
        case "Living Product Challenge Certified":
            return thumbnail.isLivingProductChallengeCertified;
    }

    return false;
}

// ===============================================================================

function applyAlignmentFilter(
    thumbnail: DeclareLabelThumbnail,
    filterValue: string
) {
    switch (filterValue) {
        case "LEED v4 and 4.1 BPDO Material Ingredients Option 1":
            return thumbnail.isLeedOption1;
        case "LEED v4.1 BPDO Material Ingredients Option 2":
            return thumbnail.isLeedOption2;
        case "LBC I-13 Red List":
            return (thumbnail.declarationStatus === DeclareStatus.RedListFree) || (thumbnail.declarationStatus === DeclareStatus.RedListApproved);
        case "LBC I-14 Responsible Sourcing":
            return thumbnail.isResponsibleSourcingEnabled;
        case "LBC I-10 Healthy Interior Performance":
            return thumbnail.isHealthyInteriorPerformanceEnabled;
        case "EU CoC Screened":
            return thumbnail.isEuChemicalOfConcernScreened;
        case "Enterprise Green Community Criteria - Meets Mandatory Criteria":
            return (thumbnail.enterpriseGreenCommunityStandardsCompliance === EnterpriseGreenCommunityStandardsCompliance.Complies) ||
                (thumbnail.enterpriseGreenCommunityStandardsCompliance === EnterpriseGreenCommunityStandardsCompliance.Exceeds);
        case "Enterprise Green Community Criteria - Meets Mandatory & Optional Criteria":
            return thumbnail.enterpriseGreenCommunityStandardsCompliance === EnterpriseGreenCommunityStandardsCompliance.Exceeds;
    }

    return false;
}

// ===============================================================================

function applyActiveFilters(
    allDeclareLabelThumbnails: DeclareLabelThumbnail[],
    activeFilters: ActiveFilter[]
): DeclareLabelThumbnail[] {
    if (activeFilters.length === 0) {
        return allDeclareLabelThumbnails;
    }

    return allDeclareLabelThumbnails
        .filter(declareLabelThumbnail => {
            const passedMap =
                activeFilters.reduce(
                    (accumulator, currentFilter) => {
                        accumulator[currentFilter.filterId] = false;

                        return accumulator;
                    },
                    {} as { [filterId: string]: boolean });

            for (let activeFilter of activeFilters) {
                let valueToCompare: string | null = null;
                let passedFilter = false;

                switch (activeFilter.filterId) {
                    case "alignment":
                        passedFilter = applyAlignmentFilter(declareLabelThumbnail, activeFilter.value);
                        break;
                    case "countries":
                        passedFilter = (declareLabelThumbnail.location.toLowerCase().indexOf(activeFilter.value.toLowerCase()) != -1);
                        break;
                    case "csi-division":
                        valueToCompare = activeFilter.value.startsWith("Division")
                            ? declareLabelThumbnail.csiDivision.trim().toLowerCase()
                            : declareLabelThumbnail.csiSubdivision.trim().toLowerCase();
                        break;
                    case "declaration-status":
                        passedFilter = applyDeclarationStatusFilter(declareLabelThumbnail, activeFilter.value);
                        break;
                    case "manufacturer":
                        valueToCompare = declareLabelThumbnail.manufacturerName.trim().toLowerCase();
                        break;
                    case "program":
                        passedFilter = applyProgramFilter(declareLabelThumbnail, activeFilter.value);
                        break;
                }

                if ((valueToCompare !== null) && (valueToCompare === activeFilter.value.trim().toLowerCase())) {
                    passedFilter = true;
                }

                if (passedFilter) {
                    passedMap[activeFilter.filterId] = true;
                }
            }

            return Object.keys(passedMap).every(filterId => passedMap[filterId]);
        });
}

// ===============================================================================

function getCurrentlyVisibleProducts(totalNumProducts: number): [number, number, number, number] {
    const $window = $(window);

    const width = $window.outerWidth() ?? 0;

    const productsPerRow =
        (width >= 991)
            ? 4
            : (width >= 768)
                ? 2
                : 1;

    const maxRowIndex = Math.floor(totalNumProducts / productsPerRow);

    const y0 = $window.scrollTop() ?? 0;
    const H_W = $window.outerHeight() ?? 0;
    const yf = y0 + H_W;

    const H_Top = 100 + 500 + 65;
    const H_R = 350 + 20;

    const getRowIndexForYPosition = (y: number) => Math.floor((y - H_Top) / H_R);
    const getProductIndexForRowIndex = (r: number) => r * productsPerRow;

    const bufferRows = 5;

    const startRowIndex = Math.max(0, getRowIndexForYPosition(y0) - bufferRows);
    const endRowIndex = Math.min(getRowIndexForYPosition(yf) + bufferRows, maxRowIndex);

    const startProductIndex = Math.max(0, getProductIndexForRowIndex(startRowIndex));
    const endProductIndex = Math.min(totalNumProducts, getProductIndexForRowIndex(endRowIndex) + productsPerRow - 1)

    const spacingTop = startRowIndex * H_R;
    const spacingBottom = (maxRowIndex - endRowIndex) * H_R;

    return [
        startProductIndex,
        endProductIndex,
        spacingTop,
        spacingBottom
    ];
}

// ===============================================================================

//
// Reducer
//
// For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
//

const unloadedState: DeclareHomeState = {
    isLoading: true,
    activeFilterContentId: null,
    activeFilters: [],
    filters: [],
    allDeclareLabelThumbnails: [],
    filteredDeclareLabelThumbnails: [],
    visibleProductsStart: -1,
    visibleProductsEnd: -1,
    productsSpacingTop: 0,
    productsSpacingBottom: 0,
    sharingFilteredView: false
};

export const reducer: Reducer<DeclareHomeState> = (state: DeclareHomeState | undefined, incomingAction: Action): DeclareHomeState => {
    if (state === undefined) {
        return unloadedState;
    }

    let filteredDeclareLabelThumbnails: DeclareLabelThumbnail[];
    let visibleProductsStart: number;
    let visibleProductsEnd: number;
    let productsSpacingTop: number;
    let productsSpacingBottom: number;
    let newActiveFilters: ActiveFilter[];

    switch (incomingAction.type) {
        case "REQUEST_DECLARE_LABEL_THUMBNAILS":
            return {
                isLoading: true,
                activeFilterContentId: state.activeFilterContentId,
                activeFilters: state.activeFilters,
                filters: state.filters,
                allDeclareLabelThumbnails: state.allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: state.filteredDeclareLabelThumbnails,
                visibleProductsStart: state.visibleProductsStart,
                visibleProductsEnd: state.visibleProductsEnd,
                productsSpacingTop: state.productsSpacingTop,
                productsSpacingBottom: state.productsSpacingBottom,
                sharingFilteredView: state.sharingFilteredView
            };
        case "RECEIVE_DECLARE_LABEL_THUMBNAILS":
            const receiveDeclareLabelThumbnailsAction = incomingAction as ReceiveDeclareLabelThumbnailsAction;

            const allDeclareLabelThumbnails = receiveDeclareLabelThumbnailsAction.declareLabelThumbnails;

            filteredDeclareLabelThumbnails = applyActiveFilters(allDeclareLabelThumbnails, state.activeFilters);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(filteredDeclareLabelThumbnails.length);

            return {
                isLoading: false,
                activeFilterContentId: state.activeFilterContentId,
                activeFilters: state.activeFilters,
                filters: getFiltersForThumbnails(receiveDeclareLabelThumbnailsAction.declareLabelThumbnails),
                allDeclareLabelThumbnails: allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: filteredDeclareLabelThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom,
                sharingFilteredView: state.sharingFilteredView
            };
        case "SET_ACTIVE_FILTER_CONTENT":
            return {
                isLoading: state.isLoading,
                activeFilterContentId: (<SetActiveFilterContentAction>incomingAction).filterId,
                activeFilters: state.activeFilters,
                filters: state.filters,
                allDeclareLabelThumbnails: state.allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: state.filteredDeclareLabelThumbnails,
                visibleProductsStart: state.visibleProductsStart,
                visibleProductsEnd: state.visibleProductsEnd,
                productsSpacingTop: state.productsSpacingTop,
                productsSpacingBottom: state.productsSpacingBottom,
                sharingFilteredView: state.sharingFilteredView
            };
        case "SET_ACTIVE_FILTERS":
            const activeFiltersToSet = (<SetActiveFiltersAction>incomingAction).activeFilters;

            filteredDeclareLabelThumbnails = applyActiveFilters(state.allDeclareLabelThumbnails, activeFiltersToSet);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(filteredDeclareLabelThumbnails.length);

            return {
                isLoading: state.isLoading,
                activeFilterContentId: null,
                activeFilters: activeFiltersToSet,
                filters: state.filters,
                allDeclareLabelThumbnails: state.allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: filteredDeclareLabelThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom,
                sharingFilteredView: state.sharingFilteredView
            };
        case "ACTIVATE_FILTER":
            const newActiveFilterId = (<ActivateFilterAction>incomingAction).filterId;
            const newActiveFilterValue = (<ActivateFilterAction>incomingAction).filterValue;

            newActiveFilters = state.activeFilters.concat({
                filterId: newActiveFilterId,
                value: newActiveFilterValue
            });

            filteredDeclareLabelThumbnails = applyActiveFilters(state.allDeclareLabelThumbnails, newActiveFilters);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(filteredDeclareLabelThumbnails.length);

            return {
                isLoading: state.isLoading,
                activeFilterContentId: null,
                activeFilters: newActiveFilters,
                filters: state.filters,
                allDeclareLabelThumbnails: state.allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: filteredDeclareLabelThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom,
                sharingFilteredView: state.sharingFilteredView
            };
        case "DEACTIVATE_FILTER":
            const filterIdToRemove = (<DeactivateFilterAction>incomingAction).filterId;
            const filterValueToRemove = (<DeactivateFilterAction>incomingAction).filterValue;

            newActiveFilters = state.activeFilters.filter(f => (f.filterId !== filterIdToRemove) || (f.value !== filterValueToRemove));

            filteredDeclareLabelThumbnails = applyActiveFilters(state.allDeclareLabelThumbnails, newActiveFilters);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(filteredDeclareLabelThumbnails.length);

            return {
                isLoading: state.isLoading,
                activeFilterContentId: state.activeFilterContentId,
                activeFilters: newActiveFilters,
                filters: state.filters,
                allDeclareLabelThumbnails: state.allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: filteredDeclareLabelThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom,
                sharingFilteredView: state.sharingFilteredView
            };
        case "UPDATE_VISIBLE_PRODUCTS":
            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(state.filteredDeclareLabelThumbnails.length);

            return {
                isLoading: state.isLoading,
                activeFilterContentId: state.activeFilterContentId,
                activeFilters: state.activeFilters,
                filters: state.filters,
                allDeclareLabelThumbnails: state.allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: state.filteredDeclareLabelThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom,
                sharingFilteredView: state.sharingFilteredView
            };
        case "SHARE_FILTERED_VIEW":
            return {
                isLoading: state.isLoading,
                activeFilterContentId: state.activeFilterContentId,
                activeFilters: state.activeFilters,
                filters: state.filters,
                allDeclareLabelThumbnails: state.allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: state.filteredDeclareLabelThumbnails,
                visibleProductsStart: state.visibleProductsStart,
                visibleProductsEnd: state.visibleProductsEnd,
                productsSpacingTop: state.productsSpacingTop,
                productsSpacingBottom: state.productsSpacingBottom,
                sharingFilteredView: true
            };
        case "CLOSE_SHARE_FILTERED_VIEW":
            return {
                isLoading: state.isLoading,
                activeFilterContentId: state.activeFilterContentId,
                activeFilters: state.activeFilters,
                filters: state.filters,
                allDeclareLabelThumbnails: state.allDeclareLabelThumbnails,
                filteredDeclareLabelThumbnails: state.filteredDeclareLabelThumbnails,
                visibleProductsStart: state.visibleProductsStart,
                visibleProductsEnd: state.visibleProductsEnd,
                productsSpacingTop: state.productsSpacingTop,
                productsSpacingBottom: state.productsSpacingBottom,
                sharingFilteredView: false
            };
    }

    return state;
};
