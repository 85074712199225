//
// Utility extensions and functions
//

// ===============================================================================

declare global {
    interface String {
        sanitizeForUrlPath(this: string): string;
        trimWordsToMaxNumChars(this: string, maxNumChars: number): string;
        trimStartString(this: string, startString: string): string;
        trimEndString(this: string, endString: string): string;
        nullOrEmptyCoalesce(this: string, coalesceValue: string): string;
    }

    interface Array<T> {
        distinct(this: Array<T>): Array<T>;
        firstOrDefault(this: Array<T>, predicate: (obj: T) => boolean): T;
        selectMany<TSelector>(this: Array<T>, selector: (obj: T) => Array<TSelector>): Array<TSelector>;
        orderBy(this: Array<T>, selector: (obj: T) => string): Array<T>;
    }
}

// ===============================================================================

//
// String extensions
//

String.prototype.sanitizeForUrlPath = function (this: string): string {
    let sb = "";

    const source = this.trim();

    let lastCharAppended = " ";

    for (let i = 0; i < source.length; ++i) {
        const c = source[i];

        if (((c === '-') || (c === ' ') || (c === '/')) && (lastCharAppended !== '-')) {
            lastCharAppended = '-';
            sb += '-';
        }
        else if (((c >= 'a') && (c <= 'z')) || ((c >= '0') && (c <= '9'))) {
            lastCharAppended = c;
            sb += c;
        }
        else if ((c >= 'A') && (c <= 'Z')) {
            lastCharAppended = c.toLowerCase();
            sb += lastCharAppended;
        }
    }

    return sb;
};

// ===============================================================================

String.prototype.trimWordsToMaxNumChars = function (this: string, maxNumChars: number): string {
    if (this.length <= maxNumChars) {
        return this;
    }

    let chop = this.substring(0, maxNumChars);

    if (this[maxNumChars] === " ") {
        return chop + "...";
    }

    let i = chop.length - 1;

    while ((i > 0) && (chop[i] !== " ")) {
        --i;
    }

    chop = chop.substring(0, i + 1) + "...";

    return chop;
};

// ===============================================================================

String.prototype.trimStartString = function (this: string, startString: string): string {
    if (this.startsWith(startString)) {
        return this.substring(startString.length);
    }
    else {
        return this;
    }
};

// ===============================================================================

String.prototype.trimEndString = function (this: string, endString: string): string {
    if (this.endsWith(endString)) {
        return this.substring(0, this.length - endString.length);
    }
    else {
        return this;
    }
};

// ===============================================================================

String.prototype.nullOrEmptyCoalesce = function (this: string, coalesceValue: string): string {
    return ((this === null) || (this.length === 0))
        ? coalesceValue
        : this.valueOf();
};

// ===============================================================================

//
// Array extensions
//

Array.prototype.distinct = function<T> (this: Array<T>): Array<T> {
    return Array.from(new Set<T>(this));
};

// ===============================================================================

Array.prototype.firstOrDefault = function <T>(this: Array<T>, predicate: (obj: T) => boolean): T | null {
    for (let i = 0; i < this.length; i++) {
        if (predicate(this[i])) {
            return this[i];
        }
    }

    return null;
};

// ===============================================================================

Array.prototype.selectMany = function <T, TSelector>(this: Array<T>, selector: (obj: T) => Array<TSelector>): Array<TSelector> {
    const ret: Array<TSelector> = [];

    for (let item of this) {
        const mapped = selector(item);

        for (let mappedItem of mapped) {
            ret.push(mappedItem);
        }
    }

    return ret;
};

// ===============================================================================

Array.prototype.orderBy = function <T>(this: Array<T>, selector: (obj: T) => string): Array<T> {
    const temp = this.slice();

    temp.sort((a, b) => selector(a).localeCompare(selector(b)));

    return temp;
};

// ===============================================================================

export { }
