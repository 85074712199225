//
// The store for the state of the label detail page, which
// is a view of a single declare label product with full
// info about the product.
//

import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { ApiDataResponse } from './ApiResponse';
import { DeclareLabel } from './Models';
import '../utilities/Utilities';

// ===============================================================================

//
// State
//
// This defines the data maintained in the Redux store.
//

export interface DeclareLabelDetailState {
    isLoading: boolean;
    declareLabelNameId: string;
    declareLabel: DeclareLabel | null;
    activeDeclareLabelImageUrl: string | null;
};

// ===============================================================================

//
// Actions
//
// These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side - effects; they just describe something that is going to happen.
// 

interface RequestDeclareLabelDetailAction {
    type: "REQUEST_DECLARE_LABEL_DETAIL";
    declareLabelNameIdToFetch: string;
}

interface ReceiveDeclareLabelDetailAction {
    type: "RECEIVE_DECLARE_LABEL_DETAIL";
    declareLabel: DeclareLabel;
}

interface SetActiveDeclareLabelImageUrlAction {
    type: "SET_ACTIVE_DECLARE_LABEL_IMAGE_URL";
    declareLabelImageUrl: string | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDeclareLabelDetailAction |
    ReceiveDeclareLabelDetailAction |
    SetActiveDeclareLabelImageUrlAction;

// ===============================================================================

//
// Action Creators
//
// These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//

export const actionCreators = {
    loadDeclareLabel: (declareLabelNameIdToFetch: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState &&
            appState.declareLabelDetailState &&
            !appState.declareLabelDetailState.isLoading &&
            ((appState.declareLabelDetailState.declareLabel === null) ||
                (appState.declareLabelDetailState.declareLabelNameId !== declareLabelNameIdToFetch))) {
            fetch(`api/declarelabels/${declareLabelNameIdToFetch}`)
                .then(response => response.json() as Promise<ApiDataResponse<DeclareLabel>>)
                .then(apiDataResponse => {
                    dispatch({ type: "RECEIVE_DECLARE_LABEL_DETAIL", declareLabel: apiDataResponse.data });
                });

            dispatch({ type: "REQUEST_DECLARE_LABEL_DETAIL", declareLabelNameIdToFetch: declareLabelNameIdToFetch });
        }
    },
    setActiveDeclareLabelImageUrl: (declareLabelImageUrl: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState && appState.declareLabelDetailState) {
            dispatch({ type: "SET_ACTIVE_DECLARE_LABEL_IMAGE_URL", declareLabelImageUrl: declareLabelImageUrl });
        }
    }
};

// ===============================================================================

//
// Reducer
//
// For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
//

const unloadedState: DeclareLabelDetailState = {
    declareLabel: null,
    declareLabelNameId: "",
    isLoading: false,
    activeDeclareLabelImageUrl: null
};

export const reducer: Reducer<DeclareLabelDetailState> = (state: DeclareLabelDetailState | undefined, incomingAction: Action): DeclareLabelDetailState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (incomingAction.type) {
        case "REQUEST_DECLARE_LABEL_DETAIL":
            return {
                isLoading: true,
                declareLabel: null,
                declareLabelNameId: (incomingAction as RequestDeclareLabelDetailAction).declareLabelNameIdToFetch,
                activeDeclareLabelImageUrl: state.activeDeclareLabelImageUrl
            };
        case "RECEIVE_DECLARE_LABEL_DETAIL":
            return {
                isLoading: false,
                declareLabel: (incomingAction as ReceiveDeclareLabelDetailAction).declareLabel,
                declareLabelNameId: state.declareLabelNameId,
                activeDeclareLabelImageUrl: state.activeDeclareLabelImageUrl
            };
        case "SET_ACTIVE_DECLARE_LABEL_IMAGE_URL":
            return {
                isLoading: state.isLoading,
                declareLabel: state.declareLabel,
                declareLabelNameId: state.declareLabelNameId,
                activeDeclareLabelImageUrl: (incomingAction as SetActiveDeclareLabelImageUrlAction).declareLabelImageUrl
            };
    }

    return state;
};
